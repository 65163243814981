import { Box, Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import Lottie from "react-lottie";
import * as animationData from "../../lotties/hero.json";

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box
      paddingTop="140px"
      paddingBottom="100px"
      backgroundImage="radial-gradient(#CCD0D5 1px, transparent 0)"
      backgroundSize="40px 40px"
    >
      <Container maxWidth="7xl">
        <Flex direction="row">
          <Box>
            <Text fontSize="64px">Hi, I'm Niharika</Text>
            <Text height={{ base: "100px", md: "auto" }}>
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed once, initially
                  "Blogger",
                  1000,
                  "Educational Content Creator",
                  1000,
                ]}
                speed={50}
                style={{ fontSize: "2em" }}
                repeat={Infinity}
              />
            </Text>

            <Box
              sx={{
                marginTop: "20px",
                color: "gray.500",
                borderLeftColor: "gray.900",
                borderLeft: "2px solid",
                paddingLeft: "6px",
              }}
            >
              <Text fontWeight="normal" fontSize="16px">
                Hello Everyone! I'm Niharika Tiwari, a dedicated person with a
                passion for teaching and a strong belief in the power of
                consistent effort.
              </Text>
              <Text fontWeight="normal" fontSize="16px">
                I am on a mission to inspire, empower, and help students achieve
                their academic and career goals.
              </Text>
            </Box>
          </Box>
          <Box display={{ base: "none", md: "flex" }}>
            <Lottie options={defaultOptions} width={500} />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Hero;
