import { Center, Container, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import GlobalStore from "../../store/GlobalStore";
import PostCard from "../post-card/PostCard";
import { IUIBlogCategory } from "../../interfaces/GlobalInterfaces";
import { convertTimeFormat } from "../../utils/GlobalUtils";
import { TrophyIcon } from "@heroicons/react/24/outline";

const MostLikedPosts = observer(() => {
  return (
    <Container marginTop="50px" maxW="6xl">
      <Flex gap={2} justifyContent="center" alignItems="center">
        <TrophyIcon style={{ height: "2rem" }} />
        <Text fontSize="x-large">Trending Blogs</Text>
      </Flex>
      <Center marginTop="20px">
        <SimpleGrid
          spacing={{ sm: "0", md: "40px" }}
          columns={{ sm: 2, md: 2 }}
        >
          {GlobalStore.getMostLikedPosts?.map((post) => (
            <PostCard
              showDescription
              postDescription={post.postMeta.postDescription}
              showImage={false}
              isLoading={false}
              key={post.postMeta.id}
              maxWidth={{ sm: "auto", md: "600px" }}
              postId={post.postMeta.id}
              postCategory={GlobalStore.getBlogCategoriesList(
                post.postCategories
              ).map((category: IUIBlogCategory) => category.label)}
              minWidth={{ sm: "auto", md: "550px" }}
              postImgUrl={
                post.postMeta.postImageUrl
                  ? (post.postMeta.postImageUrl as string)
                  : "https://via.placeholder.com/150"
              }
              postTitle={post.postMeta.postTitle}
              postWriterName={post.postMeta.userName}
              timeToRead={post.postMeta.timeToRead}
              createOn={convertTimeFormat(post.postMeta.createdOn)}
            />
          ))}
        </SimpleGrid>
      </Center>
    </Container>
  );
});

export default MostLikedPosts;
