import React, { FC } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/Constants";
import GlobalStore from "../../store/GlobalStore";
import {
  ArrowLeftStartOnRectangleIcon,
  BookmarkIcon,
  DocumentArrowDownIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";

type ProfileAvatarProps = {
  toggleDrawerforMobileView?: CallableFunction;
};

const ProfileAvatar: FC<ProfileAvatarProps> = observer(
  ({ toggleDrawerforMobileView }) => {
    const navigate = useNavigate();

    const logOut = () => {
      GlobalStore.clearUserData();
      navigate(routes.HOME);
    };

    const handleSavedBlogClick = () => {
      if (toggleDrawerforMobileView) {
        toggleDrawerforMobileView();
      }
      navigate(routes.SAVED_BLOGS);
    };

    return (
      <Box>
        <Box display={{ base: "none", md: "flex" }}>
          <Menu size="sm">
            <MenuButton>
              <Avatar
                size="sm"
                cursor="pointer"
                name={GlobalStore.getUserData.email as string}
                src={GlobalStore.getUserData.profilePicUrl as string}
              />
            </MenuButton>
            <MenuList>
              <MenuGroup title={GlobalStore.getUserData.firstName as string}>
                {/* TODO: Add My Profile functionality */}
                {/* <MenuItem>My Profile</MenuItem> */}
                {GlobalStore.getIsUserWriter && (
                  <>
                    <MenuItem onClick={() => navigate(routes.MY_BLOGS)}>
                      <Flex direction="row" gap={2}>
                        <DocumentArrowDownIcon style={{ height: "24px" }} />
                        <Text fontSize="xs">My Blogs</Text>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => navigate(routes.MY_IMAGES)}>
                      <Flex direction="row" gap={2}>
                        <PhotoIcon style={{ height: "24px" }} />
                        <Text fontSize="xs">My Images</Text>
                      </Flex>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                {GlobalStore.getIsUserAdmin && (
                  <>
                    <MenuItem onClick={() => navigate(routes.MY_BLOGS)}>
                      <Text fontSize="xs">All blogs</Text>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                {GlobalStore.getIsUserReader && (
                  <>
                    <MenuItem onClick={() => navigate(routes.SAVED_BLOGS)}>
                      <Flex direction="row" gap={2}>
                        <BookmarkIcon style={{ height: "24px" }} />
                        <Text fontSize="xs">Saved Blogs</Text>
                      </Flex>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem onClick={logOut}>
                  <Flex direction="row" gap={2}>
                    <ArrowLeftStartOnRectangleIcon style={{ height: "24px" }} />
                    <Text fontSize="xs">Log out</Text>
                  </Flex>
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Box>

        {/* Mobile view */}
        <Flex direction="column" gap={2} display={{ base: "flex", md: "none" }}>
          <Flex gap={4} alignItems="center" direction="column">
            <Avatar
              size="sm"
              cursor="pointer"
              name={GlobalStore.getUserData.email as string}
              src={GlobalStore.getUserData.profilePicUrl as string}
            />
            {/* TODO: Add My Profile functionality */}
            {/* <Button variant='link'>My Profile</Button> */}
            {GlobalStore.getIsUserWriter && (
              <>
                <Button
                  variant="link"
                  onClick={() => navigate(routes.MY_BLOGS)}
                >
                  <Text fontSize="xs">My Blogs</Text>
                </Button>
                <Button
                  variant="link"
                  onClick={() => navigate(routes.MY_IMAGES)}
                >
                  <Text fontSize="xs">My Images</Text>
                </Button>
              </>
            )}
            {GlobalStore.getIsUserAdmin && (
              <>
                <Button
                  variant="link"
                  onClick={() => navigate(routes.MY_BLOGS)}
                >
                  <Text fontSize="xs">All blogs</Text>
                </Button>
              </>
            )}
            {GlobalStore.getIsUserReader && (
              <>
                <Button variant="link" onClick={handleSavedBlogClick}>
                  <Text fontSize="sm">Saved Blogs</Text>
                </Button>
              </>
            )}
            <Button variant="link" onClick={logOut}>
              <Text fontSize="sm">Log out</Text>
            </Button>
          </Flex>
          <Divider />
        </Flex>
      </Box>
    );
  }
);

export default ProfileAvatar;
