import { Flex } from "@chakra-ui/react";
import React from "react";
import { SocialIcon } from "react-social-icons";

const SocialLinks = () => {
  return (
    <Flex marginTop="50px" gap={2} justifyContent="center" direction="row">
      <SocialIcon
        href="https://www.youtube.com/@studywithniharikatiwari1576"
        style={{ width: "30px", height: "30px" }}
        network="youtube"
      />
      <SocialIcon
        href="https://www.facebook.com/Niharika7000"
        style={{ width: "30px", height: "30px" }}
        network="facebook"
      />
      <SocialIcon
        href="https://www.instagram.com/Studywithniharikatiwari"
        style={{ width: "30px", height: "30px" }}
        network="instagram"
      />
      <SocialIcon
        href="https://t.me/ntiwa"
        style={{ width: "30px", height: "30px" }}
        network="telegram"
      />
    </Flex>
  );
};

export default SocialLinks;
