import {
  Box,
  Center,
  Container,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import GlobalStore from "../store/GlobalStore";
import CategorizedBlogSection from "../components/category-wise-blog-section/CategorizedBlogSection";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { Select } from "chakra-react-select";
import { IUIBlogCategory } from "../interfaces/GlobalInterfaces";
import BlogSearchAutoComplete from "../components/blog-search-autocomplete/BlogSearchAutoComplete";

const Blogs = observer(() => {
  const [selectedSubject, setSelectedSubject] = useState<IUIBlogCategory>();
  const [blogs, setBlogs] = useState(GlobalStore.getReadableBlogs);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredBlogs, setFilteredBlogs] = useState(
    GlobalStore.getReadableBlogs
  );

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedSubject(selectedOptions);
    if (selectedOptions.value !== -1) {
      setBlogs(
        GlobalStore.blogStore.categorizedBlogsDic[selectedOptions.value]
      );
      setFilteredBlogs(
        GlobalStore.blogStore.categorizedBlogsDic[selectedOptions.value]
      );
    } else {
      setBlogs(GlobalStore.getReadableBlogs);
      setFilteredBlogs(GlobalStore.getReadableBlogs);
    }
  };

  const filterBlog = (id: number) => {
    const tempFilteredBlogs = blogs.filter((blog) => {
      return blog.postMeta.id === id;
    });
    setFilteredBlogs(tempFilteredBlogs);
  };

  useEffect(() => {
    setBlogs(GlobalStore.getReadableBlogs);
    setFilteredBlogs(GlobalStore.getReadableBlogs);
  }, [GlobalStore.getReadableBlogs]);

  // Fetch all blogs here not in store
  useEffect(() => {
    const fetchAllPosts = async () => {
      setIsLoading(true);
      try {
        await GlobalStore.fetchReadablePosts();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (GlobalStore.getReadableBlogs.length === 0) {
      fetchAllPosts();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs | Niharika.academy</title>
        <meta
          property="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
        <meta property="og:title" content="Blogs | Niharika.academy" />
        <meta
          property="og:description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      {isLoading ? (
        <Center className="blog-view-spinner-container">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      ) : (
        <Container marginTop="50px" marginBottom="50px" maxWidth="7xl">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            direction="row"
            marginBottom="50px"
          >
            <Text fontSize="20px">Browse blogs</Text>
          </Flex>
          <SimpleGrid spacing="40px" columns={{ sm: 2, md: 2 }}>
            <Flex direction="column">
              <Text fontWeight="normal" fontSize="small">
                Select Subject
              </Text>
              <Select
                onChange={handleSelectChange}
                size="sm"
                tagVariant="solid"
                options={[
                  { value: -1, label: "All Blogs" },
                  ...GlobalStore.getAllBlogCategories,
                ]}
                value={selectedSubject}
                placeholder="All Blogs"
              />
            </Flex>
            <BlogSearchAutoComplete filterBlog={filterBlog} blogs={blogs} />
          </SimpleGrid>
          <Box marginTop="50px">
            <CategorizedBlogSection blogs={filteredBlogs} />
          </Box>
        </Container>
      )}
    </>
  );
});

export default Blogs;
