import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import * as animationData from "../lotties/unsubscribe.json";
import Lottie from "react-lottie";
import { updateSubscriptionStatus } from "../service/UserService";
import GlobalStore from "../store/GlobalStore";
import { Helmet } from "react-helmet";
import { HttpStatusCode } from "axios";

const Unsubscribe = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleUnsubscribeClick = async () => {
    setIsLoading(true);
    try {
      if (GlobalStore.unsubscribeUserEmail) {
        const resp = await updateSubscriptionStatus(
          GlobalStore.unsubscribeUserEmail,
          false
        );
        if (resp === HttpStatusCode.Ok) {
          toast({
            description: `Unsubscribed, You can close this page now!!`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            description: `Cannot Unsubscribe, Please try again later`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        toast({
          description: `Cannot Unsubscribe, Please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        description: `Cannot Unsubscribe, Please try again later`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Unsubscribe | Niharika.academy</title>
        {/* TODO: Add meaningful description */}
        <meta
          property="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <Flex
        marginTop="50px"
        width="100%"
        alignItems="center"
        direction="column"
      >
        <Box width="fit-content" borderRadius="50%" backgroundColor="gray.100">
          <Lottie options={defaultOptions} width={150} />
        </Box>
        <Text textAlign="center" fontSize="30px" marginTop="20px">
          We'll be sad to see you go, but we understand
        </Text>
        <Text
          textAlign="center"
          fontSize="18px"
          fontWeight="normal"
          color="#9A9494"
        >
          Unsubscribe to bid us farewell
        </Text>
        <Button
          isLoading={isLoading}
          marginTop="20px"
          width={{ base: "100%", md: "auto" }}
          padding="0 20px 0 20px"
          onClick={handleUnsubscribeClick}
          colorScheme="purple"
          size="lg"
        >
          Unsubscribe
        </Button>
      </Flex>
    </>
  );
};

export default Unsubscribe;
