import React, { FC, Key } from "react";
import {
  Code,
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { ClockIcon } from "@heroicons/react/24/outline";
import PostWriterDetails from "../post-writer-details/PostWriterDetails";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../utils/Constants";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getCategoryColorScheme, toTitleCase } from "../../utils/GlobalUtils";

type MostRecentPostCardProps = {
  postId: number;
  postTitle: string;
  postDescription: string;
  postImage: string;
  postWriterName: string;
  createOn: string;
  timeToRead: number;
  postCategory: Array<string>;
};

const MostRecentPostCard: FC<MostRecentPostCardProps> = ({
  postId,
  postTitle,
  postDescription,
  postImage,
  postWriterName,
  createOn,
  timeToRead,
  postCategory,
}) => {
  const navigate = useNavigate();
  const cardCategoryColorScheme = getCategoryColorScheme(
    postCategory.at(0) || ""
  );

  return (
    <Card
      onClick={() => {
        navigate(`${routes.BLOG_VIEW}/${postId}`);
      }}
      cursor="pointer"
      borderRadius="2rem 2rem 0.5rem  0.5rem"
      direction={{ base: "column", md: "row" }}
      overflow="hidden"
      variant="outline"
      position="relative"
      transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "lg",
      }}
    >
      <Box>
        <Image
          objectFit="cover"
          maxW={{ base: "100%", md: "500px" }}
          src={postImage}
          alt="most-recent-post-card-image"
        />
      </Box>
      <Tag
        size="md"
        left="-5px"
        variant="solid"
        position="absolute"
        top="20px"
        colorScheme="purple"
        padding="5px"
      >
        <ClockIcon style={{ height: "24px", marginLeft: "5px" }} />
        <TagLabel fontWeight="bold" marginLeft="5px">
          Recently Added
        </TagLabel>
      </Tag>

      <Stack>
        <CardBody>
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Code
              key={postCategory[0] as Key}
              colorScheme={cardCategoryColorScheme}
              marginBottom="10px"
            >
              {toTitleCase(postCategory.at(0))}
            </Code>
          </Flex>
          <Heading
            _hover={{ textDecoration: "underline", cursor: "pointer" }}
            size={{ sm: "sm", md: "md" }}
          >
            <Link to={`${routes.BLOG_VIEW}/${postId}`}>{postTitle}</Link>
          </Heading>

          <Heading
            marginTop="10px"
            noOfLines={3}
            as="h3"
            size="sm"
            fontWeight="normal"
          >
            {postDescription}...
          </Heading>
        </CardBody>
        <Divider />
        <PostWriterDetails
          postWriterName={postWriterName}
          createOn={createOn}
          timeToRead={timeToRead}
        />
      </Stack>
    </Card>
  );
};

export default MostRecentPostCard;
