import React from "react";
import Hero from "../components/hero/Hero";
import { Helmet } from "react-helmet";
import { Container, Divider, SimpleGrid } from "@chakra-ui/react";
import AboutMeCard from "../components/about-me-card/AboutMeCard";
import { aboutMeCardsData } from "../utils/Constants";

const AboutMe = () => {
  return (
    <Container maxW="9xl">
      <Helmet>
        <title>About Me | Niharika.academy</title>
        <meta
          name="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <Hero />

      <Divider marginTop="50px" />
      <Container marginTop="50px" maxW="6xl">
        <SimpleGrid columns={{ sm: 2, md: 2 }} spacing="40px">
          {aboutMeCardsData.map((aboutMeData) => (
            <AboutMeCard
              height="auto"
              icon={aboutMeData.icon}
              title={aboutMeData.title}
              description={aboutMeData.description}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default AboutMe;
