import axios from "axios";
import { urlFiles } from "../utils/Constants";

async function fetchAllImagesForUser(jwtToken: string) {
  try {
    const resp = await axios.get(`${urlFiles}/get-all-images`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch all images for user");
  }
}

async function uploadImage(jwtToken: string, image: any) {
  const body = new FormData();
  body.set("image", image);
  try {
    const resp = await axios.post(`${urlFiles}/upload-image`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't upload image");
  }
}

export { fetchAllImagesForUser, uploadImage };
