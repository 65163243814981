import { Container } from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import "./YouTubePlayer.css";

interface YouTubePlayerProps {
  videoUrl: string;
}

const YouTubePlayer: FC<YouTubePlayerProps> = ({ videoUrl }): JSX.Element => {
  const [videoId, setVideoId] = useState<string>("");

  useEffect(() => {
    const regex =
      /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/watch\?.+&v=))([^&]{11})/;
    const match = videoUrl.match(regex);
    match ? setVideoId(match[1]) : setVideoId("");
  }, []);

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Container borderRadius="5px" marginTop="20px">
      {videoId !== "" ? (
        <YouTube
          videoId={videoId}
          onReady={onPlayerReady}
          className={"youtubeContainer"}
        />
      ) : (
        <>{console.log("YT videoId not found")}</>
      )}
    </Container>
  );
};

export default YouTubePlayer;
