import axios, { HttpStatusCode } from "axios";
import { routes, urlPost } from "../utils/Constants";
import GlobalStore from "../store/GlobalStore";

async function getWriterPostsFromService(userEmail: string, jwtToken: string) {
  try {
    const resp = await axios.get(
      `${urlPost}/user-posts?userEmail=${userEmail}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    return resp.data;
  } catch (err: any) {
    if (err?.response?.status === HttpStatusCode.Forbidden) {
      GlobalStore.clearUserData();
      GlobalStore.redirectTo(routes.HOME);
    }
    console.log(err);
    throw new Error("Can't fetch user blogs");
  }
}

async function getReaderSavedPostsFromService(jwtToken: string) {
  try {
    const resp = await axios.get(`${urlPost}/get-saved-posts`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return resp.data;
  } catch (err: any) {
    if (err?.response?.status === HttpStatusCode.Forbidden) {
      GlobalStore.clearUserData();
      GlobalStore.redirectTo(routes.HOME);
    }
    console.log(err);
    throw new Error("Can't fetch user blogs");
  }
}

async function deleteBlogFromService(blogId: number, jwtToken: string) {
  try {
    const resp = await axios.get(`${urlPost}/delete/${blogId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return resp.status;
  } catch (err: any) {
    if (err?.response?.status === HttpStatusCode.Forbidden) {
      GlobalStore.clearUserData();
      GlobalStore.redirectTo(routes.HOME);
    }
    console.log(err);
    throw new Error("Can't delete blog");
  }
}

async function updateBlogStatusFromService(
  blogId: number,
  blogStatus: string,
  jwtToken: string
) {
  try {
    const resp = await axios.get(
      `${urlPost}/update-blog-status/${blogId}?postStatus=${blogStatus}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    return resp.status;
  } catch (err: any) {
    if (err?.response?.status === HttpStatusCode.Forbidden) {
      GlobalStore.clearUserData();
      GlobalStore.redirectTo(routes.HOME);
    }
    console.log(err);
    throw new Error("Can't update blog status");
  }
}

async function getReadableBlogsFromService() {
  try {
    const resp = await axios.get(`${urlPost}/all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch readable blogs");
  }
}

async function getBlogContentFromBlogIdFromService(
  blogId: number,
  jwtToken: string,
  email: string
) {
  try {
    const resp = await axios.get(`${urlPost}/${blogId}?userEmail=${email}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch post content from post id");
  }
}

async function saveBlogDraftFromService(
  postTitle: string,
  postDescription: string,
  postMetaTags: string,
  postContent: string,
  postImageUrl: string | undefined,
  timeToRead: number,
  jwtToken: string
) {
  try {
    const body = {
      postTitle,
      postDescription,
      postMetaTags,
      postContent,
      postImageUrl,
      timeToRead,
    };
    const resp = await axios.post(`${urlPost}/save-draft`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save blog, please try again later");
  }
}

async function likePostFromService(postId: number, userEmail: string) {
  try {
    const body = {
      postId,
      userEmail,
    };
    const resp = await axios.post(`${urlPost}/like-post`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save blog, please try again later");
  }
}

async function isPostLikedFromService(
  postId: number,
  userEmail: string,
  jwtToken: string
) {
  try {
    const body = {
      postId,
      userEmail,
    };
    const resp = await axios.post(`${urlPost}/liked`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save blog, please try again later");
  }
}

async function updateBlogDraftFromService(
  postTitle: string,
  postDescription: string,
  postMetaTags: string,
  postContent: string,
  postImageUrl: string | undefined,
  timeToRead: number,
  jwtToken: string,
  postContentId: number
) {
  try {
    const body = {
      postTitle,
      postDescription,
      postMetaTags,
      postContent,
      postImageUrl,
      timeToRead,
    };
    const resp = await axios.post(
      `${urlPost}/save-draft/${postContentId}`,
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save blog, please try again later");
  }
}

async function getMostRecentBlogsFromService() {
  try {
    const resp = await axios.get(`${urlPost}/most-recently-published`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch most recently published blogs");
  }
}

async function getMostLikedBlogsFromService() {
  try {
    const resp = await axios.get(`${urlPost}/most-liked`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch most liked blogs");
  }
}

async function getBlogCategoriesFromService() {
  try {
    const resp = await axios.get(`${urlPost}/all-post-categories`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't fetch blog categories");
  }
}

async function updateBlogTagsFromService(
  categories: Array<number>,
  postId: number,
  jwtToken: string
) {
  try {
    const body = {
      categories,
    };
    const resp = await axios.post(
      `${urlPost}/set-post-category/${postId}`,
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save blog tags, please try again later");
  }
}

async function getPostFromPostIdFromService(postId: number, userEmail: string) {
  try {
    const resp = await axios.get(
      `${urlPost}/${postId}?userEmail=${userEmail}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return resp.data;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't get post content, please try again later");
  }
}

async function sharePostFromService(postId: number, userEmail: string) {
  try {
    const body = {
      postId,
      userEmail,
    };
    const resp = await axios.post(`${urlPost}/share-post`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't send share analytics");
  }
}

async function sendBlogAlertEmailFromService(postId: number, jwtToken: string) {
  try {
    const resp = await axios.get(`${urlPost}/new-blog-alert/${postId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't send alert email, please try again later");
  }
}

async function saveReaderBlogFromService(postId: number, jwtToken: string) {
  const body = {
    postId,
  };
  try {
    const resp = await axios.post(`${urlPost}/save-reader-post`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't save reader blog, please try again later");
  }
}

async function deleteReaderBlogFromService(postId: number, jwtToken: string) {
  const body = {
    postId,
  };
  try {
    const resp = await axios.post(`${urlPost}/delete-reader-saved-post`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error("Can't delete reader blog, please try again later");
  }
}

async function suggestPostTopicFromService(
  postTopic: string,
  userEmail: string,
  jwtToken: string
) {
  try {
    const body = {
      topic: postTopic,
      userEmail: userEmail,
    };
    const resp = await axios.post(`${urlPost}/suggest-topic`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return resp.status;
  } catch (err: any) {
    console.log(err);
    throw new Error(
      "Can't suggest this topic right now, please try again later"
    );
  }
}

async function getJsonContentFromS3Url(url: string) {
  try {
    const resp = await axios.get(url);
    return resp.data;
  } catch (error) {
    console.log(error);
    throw new Error("Can't fetch blog content");
  }
}

export {
  isPostLikedFromService,
  likePostFromService,
  getWriterPostsFromService,
  deleteBlogFromService,
  updateBlogStatusFromService,
  getReadableBlogsFromService,
  getBlogContentFromBlogIdFromService,
  saveBlogDraftFromService,
  updateBlogDraftFromService,
  getBlogCategoriesFromService,
  updateBlogTagsFromService,
  getPostFromPostIdFromService,
  sharePostFromService,
  sendBlogAlertEmailFromService,
  getMostRecentBlogsFromService,
  suggestPostTopicFromService,
  getMostLikedBlogsFromService,
  getJsonContentFromS3Url,
  getReaderSavedPostsFromService,
  saveReaderBlogFromService,
  deleteReaderBlogFromService,
};
