import { Avatar, Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React, { FC } from "react";

interface PostWriterDetailsProps {
  postWriterName: string;
  createOn: string;
  timeToRead?: number;
  showMinsToRead?: boolean;
}

const PostWriterDetails: FC<PostWriterDetailsProps> = ({
  postWriterName,
  createOn,
  timeToRead,
  showMinsToRead = true,
}): JSX.Element => {
  return (
    <>
      <SimpleGrid
        paddingLeft="10px"
        paddingRight="16px"
        paddingBottom="10px"
        width="100%"
        columns={2}
      >
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <Avatar
            size="md"
            cursor="pointer"
            name={postWriterName}
            src={postWriterName}
          />
          <Box>
            <Text fontSize="14px" fontWeight="500">
              {postWriterName}
            </Text>
            <Flex direction="row" justifyContent="space-between">
              <Text fontSize="12px" fontWeight="normal">
                {createOn}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="flex-end">
          {showMinsToRead && (
            <Text fontSize="12px" fontWeight="normal">
              {timeToRead} mins
            </Text>
          )}
        </Flex>
      </SimpleGrid>
    </>
  );
};

export default PostWriterDetails;
