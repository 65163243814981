import { Center, Container, Flex, SimpleGrid } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import MostRecentPostCard from "./MostRecentPostCard";
import GlobalStore from "../../store/GlobalStore";
import { convertTimeFormat } from "../../utils/GlobalUtils";
import { IUIBlogCategory } from "../../interfaces/GlobalInterfaces";
import PostCard from "../post-card/PostCard";
import { Link } from "react-router-dom";
import { routes } from "../../utils/Constants";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

const MostRecentPosts = observer(() => {
  const moreRecentPosts = GlobalStore.getMostRecentPosts.slice(1);
  return (
    <Container maxW="6xl">
      {GlobalStore.getMostRecentPosts[0] && (
        <MostRecentPostCard
          postId={GlobalStore.getMostRecentPosts[0].postMeta.id}
          postCategory={GlobalStore.getBlogCategoriesList(
            GlobalStore.getMostRecentPosts[0].postCategories
          ).map((category: IUIBlogCategory) => category.label)}
          postWriterName={GlobalStore.getMostRecentPosts[0].postMeta.userName}
          timeToRead={GlobalStore.getMostRecentPosts[0].postMeta.timeToRead}
          createOn={convertTimeFormat(
            GlobalStore.getMostRecentPosts[0].postMeta.createdOn
          )}
          postImage={GlobalStore.getMostRecentPosts[0].postMeta?.postImageUrl}
          postDescription={
            GlobalStore.getMostRecentPosts[0].postMeta?.postDescription
          }
          postTitle={GlobalStore.getMostRecentPosts[0].postMeta?.postTitle}
        />
      )}
      <Center marginTop="20px">
        <SimpleGrid
          spacing={{ sm: "0", md: "20px" }}
          columns={{ sm: 2, md: 3 }}
        >
          {moreRecentPosts?.map((post) => (
            <PostCard
              showDescription
              postDescription={post.postMeta.postDescription}
              showImage={false}
              isLoading={false}
              key={post.postMeta.id}
              postId={post.postMeta.id}
              postCategory={GlobalStore.getBlogCategoriesList(
                post.postCategories
              ).map((category: IUIBlogCategory) => category.label)}
              postImgUrl={
                post.postMeta.postImageUrl
                  ? (post.postMeta.postImageUrl as string)
                  : "https://via.placeholder.com/150"
              }
              postTitle={post.postMeta.postTitle}
              postWriterName={post.postMeta.userName}
              timeToRead={post.postMeta.timeToRead}
              createOn={convertTimeFormat(post.postMeta.createdOn)}
            />
          ))}
        </SimpleGrid>
      </Center>
      <Flex width="100%" justifyContent="flex-end" color="purple.500">
        <Link to={routes.BLOGS}>
          <Flex gap={2} direction="row">
            more blogs
            <ArrowRightCircleIcon style={{ height: "24px" }} />
          </Flex>
        </Link>
      </Flex>
    </Container>
  );
});

export default MostRecentPosts;
