import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { FC, useState } from "react";
import { validateEmail, validatePassword } from "./LoginUtils";

interface EmailLoginFormProps {
  emailLogin: Function;
  isLoginLoading: Boolean;
}

const EmailLoginForm: FC<EmailLoginFormProps> = ({
  emailLogin,
  isLoginLoading,
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, actions) => emailLogin(values, actions)}
    >
      {(props) => (
        <Form>
          <Field name="email" validate={validateEmail}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>
                  <Text fontSize="14px">Email</Text>
                </FormLabel>
                <Input
                  isDisabled={isLoginLoading}
                  {...field}
                  placeholder="email"
                />
                <FormErrorMessage>
                  <Text fontSize="12px">{form.errors.email}</Text>
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Box marginTop="14px" />
          <Field name="password" validate={validatePassword}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel>
                  <Text fontSize="14px">Password</Text>
                </FormLabel>
                <InputGroup>
                  <Input
                    isDisabled={isLoginLoading}
                    type={showPassword ? "text" : "password"}
                    {...field}
                    placeholder="password"
                  />
                  <InputRightElement width="4.5rem">
                    {showPassword ? (
                      <IconButton
                        colorScheme="purple"
                        disabled
                        variant="ghost"
                        aria-label="Done"
                        size="sm"
                        icon={<ViewIcon />}
                        onClick={handleShowPasswordClick}
                      />
                    ) : (
                      <IconButton
                        colorScheme="purple"
                        variant="ghost"
                        aria-label="Done"
                        size="sm"
                        icon={<ViewOffIcon />}
                        onClick={handleShowPasswordClick}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  <Text fontSize="12px">{form.errors.password}</Text>
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            isDisabled={
              props.values.email === "" ||
              props.values.password === "" ||
              props.isSubmitting ||
              !props.isValid ||
              isLoginLoading === true
            }
            width="100%"
            marginTop="20px"
            colorScheme="purple"
            isLoading={props.isSubmitting}
            type="submit"
          >
            Sign in
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EmailLoginForm;
