import {
  Badge,
  Card,
  CardBody,
  Center,
  Code,
  Divider,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import React, { FC, Key } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../utils/Constants";
import PostWriterDetails from "../post-writer-details/PostWriterDetails";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getCategoryColorScheme, toTitleCase } from "../../utils/GlobalUtils";

interface PostCardPropsInterface {
  postImgUrl: string;
  showImage?: boolean;
  showDescription?: boolean;
  postTitle: string;
  postDescription?: string;
  postWriterName: string;
  timeToRead: number;
  createOn: string;
  postCategory: Array<string>;
  minWidth?: string | { sm: string; md: string };
  maxWidth?: string | { sm: string; md: string };
  minHeight?: string;
  postId?: number;
  isLoading: boolean;
}

const PostCard: FC<PostCardPropsInterface> = ({
  postImgUrl,
  showImage = true,
  showDescription = false,
  postTitle,
  postDescription = "",
  postWriterName,
  timeToRead,
  createOn,
  minWidth = "auto",
  maxWidth = "auto",
  minHeight = "auto",
  postCategory,
  postId,
  isLoading,
}): JSX.Element => {
  const navigate = useNavigate();
  const cardCategoryColorScheme = getCategoryColorScheme(
    postCategory.at(0) || ""
  );

  return (
    <Card
      onClick={() => {
        if (!isLoading) {
          navigate(`${routes.BLOG_VIEW}/${postId}`);
        }
      }}
      variant="outline"
      cursor="pointer !important"
      minWidth={minWidth}
      minHeight={minHeight}
      margin="10px 0 10px 0"
      maxW="sm"
      transition="transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "lg",
      }}
    >
      <CardBody>
        <Flex direction="row">
          {isLoading ? (
            <Skeleton height="20px" width="50px" />
          ) : (
            <Code
              key={postCategory[0] as Key}
              colorScheme={cardCategoryColorScheme}
              marginBottom="6px"
            >
              {toTitleCase(postCategory[0])}
            </Code>
          )}
        </Flex>
        <Center
          borderRadius="lg"
          maxHeight="150px"
          overflow="hidden"
          marginBottom="10px"
        >
          {isLoading ? (
            <Skeleton width="100%" height="150px" />
          ) : (
            showImage && (
              <Image
                alt="https://via.placeholder.com/150"
                fallbackSrc="https://via.placeholder.com/150"
                src={postImgUrl}
              />
            )
          )}
        </Center>
        {isLoading ? (
          <Skeleton height="20px" />
        ) : (
          <>
            <Flex gap={2} flexDirection="row">
              <Heading
                _hover={{ textDecoration: "underline", cursor: "pointer" }}
                as="h2"
                size="sm"
                fontWeight="600"
              >
                <Link to={`${routes.BLOG_VIEW}/${postId}`}>{postTitle}</Link>
              </Heading>
            </Flex>
            {showDescription && (
              <Heading
                as="h3"
                size="xs"
                marginTop="20px"
                fontWeight="normal"
                opacity={0.8}
              >
                {postDescription}...
              </Heading>
            )}
          </>
        )}
      </CardBody>

      {!isLoading && (
        <>
          <Divider marginBottom="10px" />
          <PostWriterDetails
            postWriterName={postWriterName}
            createOn={createOn}
            timeToRead={timeToRead}
          />
        </>
      )}
    </Card>
  );
};

export default PostCard;
