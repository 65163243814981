import React from "react";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@chakra-ui/react";

const GoBackButton = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Tooltip hasArrow label="Go Back">
      <IconButton
        display={{ base: "none", md: "flex" }}
        onClick={handleGoBack}
        isRound={true}
        size="sm"
        aria-label="Go Back"
        icon={<ArrowLeftCircleIcon style={{ height: "24px" }} />}
      />
    </Tooltip>
  );
};

export default GoBackButton;
