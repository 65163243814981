import { Container, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import UploadImage from "../components/upload-image/UploadImage";
import { observer } from "mobx-react";
import GlobalStore from "../store/GlobalStore";
import { routes } from "../utils/Constants";
import { useNavigate } from "react-router-dom";
import UserFileCard from "../components/user-file/UserFileCard";

const MyImages = observer(() => {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (GlobalStore.getUserFiles.length === 0) {
      const fetchUserFiles = async () => {
        try {
          await GlobalStore.fetchUserFilesAndUpdateStore();
        } catch (error) {
          toast({
            description: "Can't fetch your images, try after loggin-in again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          navigate(routes.HOME);
        }
      };

      fetchUserFiles();
    }
  }, []);

  console.log(GlobalStore.getUserFiles);

  return (
    <Container maxW="7xl">
      <UploadImage />

      <Text marginBottom="20px" marginTop="20px">
        Uploaded Images
      </Text>
      <SimpleGrid spacing={2} columns={{ base: 1, md: 4, lg: 5 }}>
        {GlobalStore.getUserFiles.map((file) => (
          <UserFileCard file={file} />
        ))}
      </SimpleGrid>
    </Container>
  );
});

export default MyImages;
