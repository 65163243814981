import React from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import SuggestTopicBanner from "../components/banners/SuggestTopicBanner";
import MostRecentPosts from "../components/most-recent-posts/MostRecentPosts";
import MostLikedPosts from "../components/most-liked-posts/MostLikedPosts";

const Home = observer(() => {
  return (
    <>
      <Helmet>
        <title>Home | Niharika.academy</title>
        <meta
          name="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <MostRecentPosts />
      <SuggestTopicBanner />
      <MostLikedPosts />
    </>
  );
});

export default Home;
