import { Card, CardBody, Heading, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";

const YouTubeChannelPreview = () => {
  const openYoutubeChannel = () => {
    window.open(
      "https://www.youtube.com/@studywithniharikatiwari1576",
      "_blank"
    );
  };
  return (
    <Card
      onClick={openYoutubeChannel}
      marginTop="20px"
      marginBottom="20px"
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      colorScheme="purple"
      variant="outline"
      sx={{
        cursor: "pointer",
      }}
      _hover={{
        backgroundColor: "purple.500",
        color: "white",
      }}
      transition="0.2s ease-in"
    >
      <Image
        objectFit="cover"
        alt="https://via.placeholder.com/150"
        maxW={{ base: "100%", sm: "200px" }}
        src="https://yt3.googleusercontent.com/ytc/AIdro_mx3VSP_RFNYwTVpxsc6Sp-GerIf9ih7IWWEvbM-pGbImo=s160-c-k-c0x00ffffff-no-rj"
      />

      <Stack>
        <CardBody>
          <Heading size="lg">Study With Niharika Tiwari</Heading>

          <Text py="2">
            Hello guys! Welcome to my channel, "Study With Niharika Tiwari."
            This channel is intended for all students and learners. I'm here to
            share my knowledge with you all.
          </Text>
        </CardBody>
      </Stack>
    </Card>
  );
};

export default YouTubeChannelPreview;
