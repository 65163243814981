import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import LoginOrSignUp from "../login/LoginOrSignUp";
import GlobalStore from "../../store/GlobalStore";
import { suggestPostTopicFromService } from "../../service/BlogService";
import { HttpStatusCode } from "axios";

const SuggestTopicBanner = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [postTopic, setPostTopic] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSuggestClick = async () => {
    if (
      !GlobalStore.getIsLoggedIn ||
      !GlobalStore.getUserData.token ||
      !GlobalStore.getUserData.email
    ) {
      onOpen();
    } else {
      if (postTopic === "") {
        toast({
          description: `Please enter a suggestion`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      try {
        setIsLoading(true);
        const resp = await suggestPostTopicFromService(
          postTopic,
          GlobalStore.getUserData.email,
          GlobalStore.getUserData.token
        );
        if (resp === HttpStatusCode.Ok) {
          toast({
            description: `Suggestion sent`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            description: `Can't send suggestion, Please try again later`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          description: `Can't send suggestion, Please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setPostTopic("");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        backgroundColor="purple.100"
        marginTop="50px"
        borderRadius="0"
        overflow="hidden"
        padding={{ sm: "1rem", md: "1.5rem" }}
      >
        <Container color="black" padding="30px" maxW="7xl">
          <Stack>
            <Box>
              <Heading size="lg">It's Not About Us, It's About You</Heading>
              <Text marginTop="10px" fontWeight="normal" py="2">
                We're here to serve you better!
              </Text>
              <Text fontWeight="normal" py="2">
                Let us know the topic you'd love to see on our blog, and we'll
                get writing. Your interests, your ideas—our blog.
              </Text>
            </Box>

            <Box>
              <Flex
                align="flex-end"
                gap={3}
                width="100%"
                direction={{ base: "column", md: "row" }}
              >
                <Flex width={{ base: "100%", md: "80%" }} direction="column">
                  <Text fontSize="small" fontWeight="normal">
                    Suggest a topic
                  </Text>
                  <Input
                    value={postTopic}
                    onChange={(e) => setPostTopic(e.target.value)}
                    variant="outlined"
                    backgroundColor="white"
                    placeholder="Write your suggestion here..."
                    _placeholder={{ opacity: 1, color: "gray.500" }}
                  />
                </Flex>
                <Button
                  isLoading={isLoading}
                  onClick={handleSuggestClick}
                  width={{ base: "100%", md: "20%" }}
                  colorScheme="purple"
                >
                  Suggest
                </Button>
              </Flex>
            </Box>
          </Stack>
        </Container>
      </Box>
      <LoginOrSignUp isOpen={isOpen} onClose={onClose} />
    </>
  );
});

export default SuggestTopicBanner;
