import { FC, useEffect, useState } from "react";
import Editor from "../components/editor/Editor";
import {
  Button,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import GlobalStore from "../store/GlobalStore";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils/Constants";
import {
  saveBlogDraftFromService,
  updateBlogDraftFromService,
} from "../service/BlogService";
import GoBackButton from "../components/go-back-button/GoBackButton";
import { calculateReadingTime } from "../utils/GlobalUtils";
import { IPostContent } from "../interfaces/GlobalInterfaces";
import { Helmet } from "react-helmet";
import { HttpStatusCode } from "axios";

interface WriteBlogPropsInterface {
  isEditBlogWorkflow: boolean;
}

const INITIAL_DATA = {
  time: new Date().getTime(),
  blocks: [
    {
      id: "IqJQRhrmkC",
      type: "paragraph",
      data: { text: "Blog content goes here..." },
    },
  ],
};

const WriteBlog: FC<WriteBlogPropsInterface> = observer(
  ({ isEditBlogWorkflow }): JSX.Element => {
    const toast = useToast();
    const navigate = useNavigate();
    const { blogStore } = GlobalStore;

    const [data, setData] = useState<IPostContent | any>(
      isEditBlogWorkflow
        ? blogStore.currEditedBlog
          ? blogStore.currEditedBlog.postContent
          : {}
        : INITIAL_DATA
    );
    const [title, setTitle] = useState(
      isEditBlogWorkflow ? blogStore.currEditedBlog?.postTitle : ""
    );
    const [isTitleInvalid, setIsTitleInvalid] = useState(false);

    const [blogDescription, setBlogDescription] = useState(
      isEditBlogWorkflow ? blogStore.currEditedBlog?.postDescription : ""
    );

    const [blogMetaTags, setMetaTags] = useState(
      isEditBlogWorkflow ? blogStore.currEditedBlog?.postMetaTags : ""
    );

    const handleTitleChange = (event: any) => {
      setIsTitleInvalid(false);
      setTitle(event.target.value);
    };

    const handleBlogDescriptionChange = (event: any) => {
      setBlogDescription(event.target.value);
    };

    const handleBlogMetaTagsChange = (event: any) => {
      setMetaTags(event.target.value);
    };

    const showErrorToast = (desc?: string) => {
      toast({
        description: desc
          ? desc
          : "Cannot save post, please save your data on your machine before trying again later",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    };

    const showSuccessToast = () => {
      toast({
        description: isEditBlogWorkflow ? "Blog updated" : "Blog created",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    };

    // const handleSaveEditorDataAndPublish = () => {
    //   console.log(data);
    // };

    const handleSaveEditorDataAsDraft = async () => {
      if (isEditBlogWorkflow) {
        try {
          const timeToRead = calculateReadingTime(data);
          const postImageUrl = data?.blocks.at(0)?.data.url;
          if (
            blogStore.currEditedBlog &&
            blogStore.currEditedBlog.postTitle &&
            GlobalStore.getUserData.token &&
            blogStore.currEditedBlog?.postId
          ) {
            const resp = await updateBlogDraftFromService(
              title as string,
              blogDescription || "",
              blogMetaTags || "",
              JSON.stringify(data),
              postImageUrl,
              timeToRead,
              GlobalStore.getUserData.token,
              blogStore.currEditedBlog?.postId
            );

            if (resp === HttpStatusCode.Ok) {
              showSuccessToast();
            } else {
              showErrorToast();
            }
          } else {
            showErrorToast();
          }
        } catch (error) {
          console.log(error);
          showErrorToast();
        }
      } else {
        if (title === "") {
          setIsTitleInvalid(true);
          return;
        }
        if (GlobalStore.getUserData.token && data) {
          try {
            const timeToRead = calculateReadingTime(data);
            const postImageUrl = data?.blocks.at(0)?.data.url;
            const resp = await saveBlogDraftFromService(
              title as string,
              blogDescription || "",
              blogMetaTags || "",
              JSON.stringify(data),
              postImageUrl,
              timeToRead,
              GlobalStore.getUserData.token
            );

            if (resp === HttpStatusCode.Ok) {
              showSuccessToast();
              await GlobalStore.fetchWriterBlogsAndUpdateStore();
              navigate(routes.MY_BLOGS);
            } else {
              showErrorToast();
            }
          } catch (error) {
            showErrorToast();
          }
        } else {
          showErrorToast();
        }
      }
    };

    useEffect(() => {
      if (
        isEditBlogWorkflow &&
        blogStore.currEditedBlog &&
        blogStore.currEditedBlog.postId === null
      ) {
        navigate(routes.HOME);
      }
    }, []);

    return (
      <>
        <Helmet>
          <title> Write Blog | Niharika.academy</title>
          {/* TODO: Add meaningful description */}
          <meta
            property="description"
            content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
          />
        </Helmet>
        <Container marginTop="20px">
          <GoBackButton />
          <Heading marginTop="28px" marginBottom="50px">
            {isEditBlogWorkflow ? (
              <Text color="purple.500">Edit blog:</Text>
            ) : (
              <Text color="purple.500">Write a new blog:</Text>
            )}
          </Heading>
          <InputGroup size="lg">
            <InputLeftAddon>Title</InputLeftAddon>
            <Input
              isInvalid={isTitleInvalid}
              marginBottom="20px"
              value={title as string}
              onChange={handleTitleChange}
              placeholder="Here goes your blog title"
            />
          </InputGroup>

          <Text fontSize="small" fontWeight="normal">
            Blog Description
          </Text>
          <Textarea
            marginBottom="20px"
            value={blogDescription as string}
            onChange={handleBlogDescriptionChange}
            placeholder="Here goes your blog title"
          />

          <Text fontSize="small" fontWeight="normal">
            Blog Meta Tags (Comma separated)
          </Text>
          <Textarea
            marginBottom="20px"
            value={blogMetaTags as string}
            onChange={handleBlogMetaTagsChange}
            placeholder="Here goes your blog title"
          />

          <Editor
            data={data}
            onChange={setData}
            editorblock="editorjs-container"
          />
          {/* <Button
          marginRight="6px"
          colorScheme="purple"
          onClick={handleSaveEditorDataAndPublish}
        >
          Publish
        </Button> */}
          <Button
            colorScheme="yellow"
            variant="outline"
            onClick={handleSaveEditorDataAsDraft}
          >
            Save as Draft
          </Button>
        </Container>
      </>
    );
  }
);

export default WriteBlog;
