import { jwtDecode } from "jwt-decode";
import { IJwtPayload, IUserData } from "../interfaces/GlobalInterfaces";

function fetchUserDetailFromCookie(jwtToken: string): IUserData | undefined {
  if (jwtToken) {
    const decodedJwt = jwtDecode<IJwtPayload>(jwtToken);
    const userData: IUserData = {
      email: decodedJwt.sub ? decodedJwt.sub : "",
      firstName: decodedJwt.firstName ? decodedJwt.firstName : "",
      lastName: decodedJwt.lastName ? decodedJwt.lastName : "",
      isSubscribedToNewsLetter: decodedJwt.isSubscribedToNewsLetter,
      profilePicUrl: decodedJwt.profilePicUrl ? decodedJwt.profilePicUrl : "",
      token: jwtToken,
      role: decodedJwt.role ? decodedJwt.role : [],
    };
    return userData;
  }
}

export { fetchUserDetailFromCookie };
