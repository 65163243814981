import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import InlineImage from "editorjs-inline-image";
import Title from "title-editorjs";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  header: Header,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  image: {
    class: InlineImage,
    inlineToolbar: true,
    config: {
      embed: {
        display: true,
      },
      unsplash: {
        appName: "SWNT_Editor.js_tool",
        clientId: "FOHGSUvlPfKlLjbxeQnXesjGWnaUEuoZiNnIyUwYAi4",
      },
    },
  },
  raw: Raw,
  title: Title,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
};
