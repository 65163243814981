import React from "react";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Link as ChakraLink } from "@chakra-ui/react";
import { routes } from "../../utils/Constants";

const Logo = () => {
  return (
    <Center>
      <ChakraLink
        _hover={{ textDecoration: "none" }}
        as={ReactRouterLink}
        to={routes.HOME}
      >
        <Box>
          <Flex direction="row" alignItems="baseline">
            <Text fontSize="30px">Niharika</Text>
            <Text color="purple.500">.academy</Text>
          </Flex>
        </Box>
      </ChakraLink>
    </Center>
  );
};

export default Logo;
