import { Box, Button, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import LoginOrSignUp from "./LoginOrSignUp";
import { swntEvents } from "../../utils/Constants";

const Login = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const isModalAlreadyOpened = useRef(false);

  const handleLoginEvent = (event: Event) => {
    if (isModalAlreadyOpened.current === false) {
      const customEvent = event as CustomEvent;

      setTitle(customEvent.detail?.title);
      setDescription(customEvent.detail?.description);
      isModalAlreadyOpened.current = true;
      onOpen();
    }
  };

  const handleLoginClose = () => {
    isModalAlreadyOpened.current = false;
    onClose();
  };

  // TODO: Refactor code to use this instead of useDisclosure
  // Add event listener
  useEffect(() => {
    window.addEventListener(swntEvents.LOGIN, handleLoginEvent);

    return () => {
      window.removeEventListener(swntEvents.LOGIN, handleLoginEvent);
    };
  }, []);

  return (
    <Box width="100%">
      <Button width="100%" onClick={onOpen} colorScheme="purple" size="sm">
        Login
      </Button>
      <LoginOrSignUp
        title={title}
        description={description}
        isOpen={isOpen}
        onClose={handleLoginClose}
      />
    </Box>
  );
};

export default Login;
