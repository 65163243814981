import { observer } from "mobx-react";
import React, { FC } from "react";
import { IUserFile } from "../../interfaces/GlobalInterfaces";
import {
  Card,
  CardBody,
  Center,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

type UserFileCardProps = {
  file: IUserFile;
};

const UserFileCard: FC<UserFileCardProps> = observer(
  ({ file }): JSX.Element => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const showImagePreview = () => {
      onOpen();
    };

    const handleCopyLink = async () => {
      try {
        await navigator.clipboard.writeText(file.s3Url);
        toast({
          description: `Link copied`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } catch (err) {
        toast({
          description: `Can't copy link`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    return (
      <>
        <Card position="relative">
          <CardBody>
            <Tooltip label={file.name} aria-label={`${file.name}-tooltip`}>
              <Image
                cursor="pointer"
                onClick={showImagePreview}
                src={file.s3Url}
                alt={file.name}
                borderRadius="lg"
              />
            </Tooltip>
          </CardBody>
          <IconButton
            onClick={handleCopyLink}
            size="sm"
            position="absolute"
            bottom={2}
            right={2}
            icon={<DocumentDuplicateIcon style={{ height: "20px" }} />}
            aria-label="copy-file-url"
          />
        </Card>

        <Modal size="full" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{file.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Center>
                <Image src={file.s3Url} alt={file.name} borderRadius="lg" />
              </Center>
            </ModalBody>

            <ModalFooter>
              <Text>{file.author}</Text>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);

export default UserFileCard;
