import {
  Center,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FC } from "react";
import { IEditorJSBlockData } from "../../interfaces/GlobalInterfaces";

type ImageParserProps = {
  imageData: IEditorJSBlockData;
};

const ImageParser: FC<ImageParserProps> = ({ imageData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openImageInModal = () => {
    onOpen();
  };
  return (
    <>
      <Flex
        marginTop="40px"
        marginBottom="40px"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          onClick={openImageInModal}
          cursor={imageData.unsplash?.author ? "default" : "pointer"}
          alt="https://via.placeholder.com/150"
          fallbackSrc="https://via.placeholder.com/150"
          src={imageData.url}
          borderRadius="lg"
        />
        {imageData.unsplash?.author && (
          <Text fontSize="10px">
            ( Image by{" "}
            <Link isExternal href={imageData.unsplash?.profileLink}>
              {imageData.unsplash?.author}
            </Link>
            )
          </Text>
        )}
        {imageData.caption !== "" && (
          <Text fontSize="10px">( {imageData.caption} )</Text>
        )}
      </Flex>

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Image
                src={imageData.url}
                alt={imageData.url}
                borderRadius="lg"
              />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageParser;
