import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import CategorizedBlogSection from "../components/category-wise-blog-section/CategorizedBlogSection";
import GlobalStore from "../store/GlobalStore";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { BookmarkIcon } from "@heroicons/react/24/outline";
import Lottie from "react-lottie";
import * as animationData from "../lotties/alert.json";

const ReaderSavedPosts = observer(() => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Helmet>
        <title>Saved Blogs | Niharika.academy</title>
        <meta
          property="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      {GlobalStore.blogStore.readerSavedPosts.length === 0 ? (
        <Container textAlign="center">
          <Flex alignItems="center" direction="column">
            <Lottie options={defaultOptions} width={300} />
            <Heading marginBottom="20px" as="h2" size="xl">
              Start Saving Posts You Love!{" "}
            </Heading>
            <Heading marginBottom="20px" as="h3" size="md">
              Easy access to your favorite content, anytime.
            </Heading>
            <Flex alignItems="center">
              <Text fontWeight="normal" fontSize="20px">
                Just click on
              </Text>
              <BookmarkIcon
                style={{
                  height: "24px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
              />
              <Text fontWeight="normal" fontSize="20px">
                to save a post
              </Text>
            </Flex>
          </Flex>
        </Container>
      ) : (
        <Container marginTop="50px" marginBottom="50px" maxWidth="7xl">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Text fontSize="20px">Saved blogs</Text>
          </Flex>
          <Box marginTop="20px">
            <CategorizedBlogSection
              blogs={GlobalStore.blogStore.readerSavedPosts}
            />
          </Box>
        </Container>
      )}
    </>
  );
});

export default ReaderSavedPosts;
