import React, { FC } from "react";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";
import {
  emailAuth,
  registerUser,
  validateOAuthIDToken,
} from "../../service/LoginService";
import { observer } from "mobx-react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Button,
  Text,
  Flex,
  Stack,
  Divider,
  Alert,
  AlertIcon,
  useToast,
  Link,
} from "@chakra-ui/react";
import Logo from "../header/Logo";
import { useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { fetchUserDetailFromCookie } from "../../utils/AuthUtils";
import EmailLoginForm from "./EmailLoginForm";
import SignUpForm from "./SignUpForm";
import GlobalStore from "../../store/GlobalStore";
import { HttpStatusCode } from "axios";
import posthog from "posthog-js";

type LoginOrSignUpProps = {
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  isSubscribeFlow?: boolean;
};

const LoginOrSignUp: FC<LoginOrSignUpProps> = observer(
  ({
    title = "",
    description = "",
    isOpen,
    onClose,
    isSubscribeFlow = false,
  }): JSX.Element => {
    const {
      updateUserData,
      fetchWriterBlogsAndUpdateStore,
      fetchReaderSavedBlogsAndUpdateStore,
    } = GlobalStore;
    const toast = useToast();

    const [isLoginLoading, setIsLoginLoading] = useState<Boolean>(false);
    const [isLoginFlow, setIsLoginFlow] = useState<Boolean>(true);
    const [isGoogleSignInLoading, setIsGoogleSignInLoading] =
      useState<Boolean>(false);
    const [loginError, setLoginError] = useState<string | null>(null);

    const closeLoginModal = () => {
      setLoginError(null);
      onClose();
    };

    const handleGoogleLogin = async (
      tokenResponse: Omit<
        CodeResponse,
        "error" | "error_description" | "error_uri"
      >
    ) => {
      setIsGoogleSignInLoading(true);
      try {
        if (tokenResponse.code) {
          const validationResp = await validateOAuthIDToken(tokenResponse.code);
          const userData = fetchUserDetailFromCookie(validationResp.token);
          if (userData) {
            updateUserData(userData);
            if (GlobalStore.getIsUserWriter) {
              await fetchWriterBlogsAndUpdateStore();
            }
            await fetchReaderSavedBlogsAndUpdateStore();
            // POSTHOG: Capture who has logged-in
            posthog.capture(`login_successful_${userData.email}`);
          }
          closeLoginModal();
        } else {
          console.error("Failed to get auth code");
          setLoginError("Login failed, Please try again");
        }
      } catch (error) {
        console.error(error);
        setLoginError("Login failed, Please try again");
      } finally {
        setIsGoogleSignInLoading(false);
      }
    };

    const googleLogin = useGoogleLogin({
      onSuccess: async (tokenResponse) => {
        handleGoogleLogin(tokenResponse);
      },
      flow: "auth-code",
    });

    const emailLogin = async (
      { email, password }: { email: string; password: string },
      actions: any
    ) => {
      setIsLoginLoading(true);
      try {
        const emailAuthResp = await emailAuth(email, password);
        const userData = fetchUserDetailFromCookie(emailAuthResp.token);
        if (userData) {
          updateUserData(userData);
          await fetchWriterBlogsAndUpdateStore();
        }
        actions.setSubmitting(false);
        closeLoginModal();
      } catch (error) {
        setLoginError("Invalid email or password");
        console.log(error);
      } finally {
        setIsLoginLoading(false);
      }
    };

    const signUp = async (
      {
        name,
        email,
        password,
      }: {
        name: string;
        email: string;
        password: string;
      },
      actions: any
    ) => {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      let lastName = "";
      if (nameArray.length > 1) {
        lastName = nameArray[1];
      }
      setIsLoginLoading(true);
      try {
        const signUpResp = await registerUser(
          firstName,
          lastName,
          email,
          password
        );
        if (signUpResp === HttpStatusCode.Ok) {
          toast({
            title: "Account created.",
            description:
              "We've created your account for you. Please log in to continue",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          actions.resetForm();
        }
      } catch (error: any) {
        setLoginError(error.message);
        console.log(error);
      } finally {
        setIsLoginLoading(false);
      }
    };

    const toggleLoginSignUpFlow = () => {
      setIsLoginFlow((prevVal) => !prevVal);
    };

    return (
      <Box width="100%">
        <Modal
          scrollBehavior="inside"
          isOpen={isOpen}
          onClose={closeLoginModal}
        >
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(10px) hue-rotate(90deg)"
          />
          <ModalContent>
            <ModalHeader>
              <Logo />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                {isSubscribeFlow ? (
                  <>
                    <Text fontSize="22px">Subscribe</Text>
                    <Text fontSize="14px" color="gray.500">
                      Please login to subscribe to our newsletter
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fontSize="22px">
                      {title === "" ? "Sign in" : title}
                    </Text>
                    <Text fontSize="14px" color="gray.500">
                      {description === ""
                        ? "Please login to continue to your account"
                        : description}
                    </Text>
                  </>
                )}

                {loginError !== null && (
                  <Alert variant="left-accent" status="error">
                    <AlertIcon />
                    {loginError}
                  </Alert>
                )}
              </Stack>
              <Box>
                <Stack marginBottom="20px" marginTop="20px">
                  {isLoginFlow === true ? (
                    <EmailLoginForm
                      isLoginLoading={isLoginLoading}
                      emailLogin={emailLogin}
                    />
                  ) : (
                    <SignUpForm
                      isLoginLoading={isLoginLoading}
                      signUp={signUp}
                    />
                  )}
                  <Flex
                    marginTop="10px"
                    marginBottom="10px"
                    alignItems="center"
                    direction="row"
                  >
                    <Divider />
                    <Text color="gray.500" marginLeft="10px" marginRight="10px">
                      or
                    </Text>
                    <Divider />
                  </Flex>
                  <Button
                    isDisabled={
                      isGoogleSignInLoading === true || isLoginLoading === true
                    }
                    variant="outline"
                    colorScheme="red"
                    onClick={() => googleLogin()}
                  >
                    {isGoogleSignInLoading ? (
                      <Spinner color="red.500" />
                    ) : (
                      <>
                        <Text marginRight="6px">Sign in with Google</Text>
                        <img
                          height="20px"
                          width="20px"
                          src="/assets/google.svg"
                          alt="/assets/google.svg"
                        />
                      </>
                    )}
                  </Button>
                  <Flex
                    marginTop="20px"
                    marginBottom="20px"
                    alignItems="center"
                    direction="row"
                  >
                    <Text color="gray.500" marginLeft="10px" marginRight="10px">
                      Don't have an account?
                      <Link
                        color="purple.500"
                        colorScheme="purple"
                        onClick={toggleLoginSignUpFlow}
                      >
                        {isLoginFlow ? "Sign Up" : "Sign In"}
                      </Link>
                    </Text>
                  </Flex>
                </Stack>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    );
  }
);

export default LoginOrSignUp;
