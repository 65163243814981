import React, { FC } from "react";
import { IPostMeta } from "../../interfaces/GlobalInterfaces";
import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import { routes } from "../../utils/Constants";

type SimilarPostsProps = {
  similarPosts: Array<IPostMeta>;
};

const SimilarPosts: FC<SimilarPostsProps> = ({ similarPosts }): JSX.Element => {
  return similarPosts.length > 0 ? (
    <>
      <Divider />
      <Flex
        marginTop="20px"
        marginBottom="20px"
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        <NewspaperIcon style={{ height: "2rem" }} />
        <Text fontSize="x-large">More like this</Text>
      </Flex>
      {similarPosts.length === 1 && (
        <Card>
          <CardBody>
            <HStack>
              <Text>
                {similarPosts[0].postTitle}
                <Box
                  fontSize="small"
                  fontWeight="normal"
                  opacity={0.8}
                  noOfLines={2}
                >
                  {similarPosts[0].postDescription}
                </Box>
              </Text>
              <Link
                cursor="pointer"
                href={`${routes.BLOG_VIEW}/${similarPosts[0].id}`}
              >
                <ArrowRightCircleIcon style={{ height: "5rem" }} />
              </Link>
            </HStack>
          </CardBody>
        </Card>
      )}
      {similarPosts.length === 2 && (
        <SimpleGrid columns={{ sm: 2, md: 2 }} spacing="40px">
          <Card>
            <CardBody>
              <HStack>
                <Link
                  cursor="pointer"
                  href={`${routes.BLOG_VIEW}/${similarPosts[0].id}`}
                >
                  <ArrowLeftCircleIcon style={{ height: "5rem" }} />
                </Link>

                <Text>
                  {similarPosts[0].postTitle}
                  <Box
                    fontSize="small"
                    fontWeight="normal"
                    opacity={0.8}
                    noOfLines={2}
                  >
                    {similarPosts[0].postDescription}
                  </Box>
                </Text>
              </HStack>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <HStack>
                <Text>
                  {similarPosts[1].postTitle}
                  <Box
                    fontSize="small"
                    fontWeight="normal"
                    opacity={0.8}
                    noOfLines={2}
                  >
                    {similarPosts[1].postDescription}
                  </Box>
                </Text>
                <Link
                  cursor="pointer"
                  href={`${routes.BLOG_VIEW}/${similarPosts[1].id}`}
                >
                  <ArrowRightCircleIcon style={{ height: "5rem" }} />
                </Link>
              </HStack>
            </CardBody>
          </Card>
        </SimpleGrid>
      )}
    </>
  ) : (
    <></>
  );
};

export default SimilarPosts;
