import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { uploadImage } from "../../service/FilesService";
import GlobalStore from "../../store/GlobalStore";

const UploadImage = observer(() => {
  const toast = useToast();
  const [fileToUpload, setFileToUpload] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateFileToUpload = (e: any) => {
    console.log(e);
    setFileToUpload(e.target.files[0]);
  };

  const uploadImageAndUpdateStore = async () => {
    if (!GlobalStore.getUserData || !GlobalStore.getUserData.token) {
      toast({
        description: "Can't upload image right now, login again",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(true);
    try {
      const resp = await uploadImage(
        GlobalStore.getUserData.token || "",
        fileToUpload
      );
    } catch (error) {
      console.log(error);
      toast({
        description: "Can't upload image right now, please try again later",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }

    try {
      await GlobalStore.fetchUserFilesAndUpdateStore();
    } catch (error) {
      toast({
        description:
          "Can't refresh, please refresh manually to see recently uploaded image",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box cursor="pointer">
      <Flex gap={5} direction={{ base: "column", md: "row" }}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <PaperClipIcon style={{ height: "20px" }} />
          </InputLeftElement>
          <Input
            onChange={updateFileToUpload}
            placeholder="Select image"
            type="file"
          />
        </InputGroup>
        <Button
          isLoading={isLoading}
          onClick={uploadImageAndUpdateStore}
          colorScheme="purple"
        >
          Save
        </Button>
      </Flex>
    </Box>
  );
});

export default UploadImage;
