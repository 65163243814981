import Cookies from "js-cookie";
import { fetchUserDetailFromCookie } from "../utils/AuthUtils";
import {
  deleteReaderBlogFromService,
  getBlogCategoriesFromService,
  getJsonContentFromS3Url,
  getMostLikedBlogsFromService,
  getMostRecentBlogsFromService,
  getReadableBlogsFromService,
  getReaderSavedPostsFromService,
  getWriterPostsFromService,
  saveReaderBlogFromService,
} from "../service/BlogService";
import {
  IUIBlogCategory,
  IBlogStore,
  IPostCategory,
  IReadableBlog,
  IUserData,
  IUserStore,
  IPostMeta,
  IPostContent,
  IFilesStore,
  IUserFile,
} from "../interfaces/GlobalInterfaces";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { swntEvents, readerPostAction, userRoles } from "../utils/Constants";
import { fetchAllImagesForUser } from "../service/FilesService";
import { dispatchSwntEvent } from "../utils/GlobalUtils";
import { HttpStatusCode } from "axios";

class GlobalStoreImpl {
  userStore: IUserStore = {
    userData: {
      isSubscribedToNewsLetter: null,
      email: null,
      firstName: null,
      lastName: null,
      profilePicUrl: null,
      token: null,
      role: null,
    },
  };

  blogStore: IBlogStore = {
    writerBlogs: [],
    readerSavedPosts: [],
    allBlogCategories: [],
    readableBlogs: [],
    mostRecentBlogs: [],
    mostLikedBlogs: [],
    categorizedBlogsDic: {},
    currEditedBlog: {
      postTitle: "",
      postContent: {},
      postId: null,
      postDescription: "",
      postMetaTags: "",
    },
  };

  filesStore: IFilesStore = {
    userFiles: [],
  };

  isLoading: boolean = false;
  isUnsubscribeFlow: boolean = false;
  unsubscribeUserEmail: string | undefined = "";

  constructor() {
    makeObservable(this, {
      blogStore: observable,
      updateWriterPosts: action,
      updateReadableBlogs: action,
      updateMostLikedBlogs: action,
      updateMostRecentBlogs: action,
      getReadableBlogs: computed,
      getAllBlogCategories: computed,
      getMostLikedPosts: computed,
      getMostRecentPosts: computed,
      getWriterBlogs: computed,

      userStore: observable,
      updateUserData: action,
      clearUserData: action,
      getUserData: computed,
      getIsLoggedIn: computed,
      getIsUserWriter: computed,

      filesStore: observable,
      updateUserFiles: action,
      getUserFiles: computed,

      isLoading: observable,
    });
  }

  async fetchData() {
    await this.fetchMostRecentPosts();
    await this.fetchMostLikedPosts();
    await this.fetchPostCategories();
    await this.fetchUserFromTokenAndUpdateStore();
    await this.fetchWriterBlogsAndUpdateStore();
    await this.fetchReaderSavedBlogsAndUpdateStore();
  }

  clearUserData = () => {
    runInAction(() => {
      this.userStore.userData.email = null;
      this.userStore.userData.firstName = null;
      this.userStore.userData.lastName = null;
      this.userStore.userData.profilePicUrl = null;
      this.userStore.userData.token = null;
      this.userStore.userData.role = null;
      Cookies.remove("swnt_jwtToken");
    });
  };

  // User --start

  updateUserData = (userData: IUserData) => {
    this.clearUserData();
    runInAction(() => {
      this.userStore.userData.email = userData.email;
      this.userStore.userData.firstName = userData.firstName;
      this.userStore.userData.lastName = userData.lastName;
      this.userStore.userData.profilePicUrl = userData.profilePicUrl;
      this.userStore.userData.token = userData.token;
      this.userStore.userData.role = userData.role;
      this.userStore.userData.isSubscribedToNewsLetter =
        userData.isSubscribedToNewsLetter;

      Cookies.remove("swnt_jwtToken");
      Cookies.set("swnt_jwtToken", userData.token as string, {
        expires: 7,
        // secure: true,
      });
    });
  };

  get getUserData() {
    this.fetchUserFromTokenAndUpdateStore();
    return this.userStore.userData;
  }

  get getIsUserWriter() {
    return this.getUserData.role?.at(0)?.authority === userRoles.WRITER;
  }

  get getIsUserAdmin() {
    return this.getUserData.role?.at(0)?.authority === userRoles.ADMIN;
  }

  get getIsUserReader() {
    return this.getUserData.role?.at(0)?.authority === userRoles.READER;
  }

  get getIsLoggedIn() {
    return this.getUserData.email !== null;
  }

  // User --start
  // -----------------------------------------------
  // Blogs -- start
  updateWriterPosts = (posts: Array<IReadableBlog>) => {
    runInAction(() => {
      this.blogStore.writerBlogs = [];
      this.blogStore.writerBlogs = posts;
    });
  };

  updateReaderSavedPosts = (readerSavedPosts: Array<IReadableBlog>) => {
    runInAction(() => {
      this.blogStore.readerSavedPosts = [];
      this.blogStore.readerSavedPosts = readerSavedPosts;
    });
  };

  updateReadableBlogs = (readableBlogs: Array<IReadableBlog>) => {
    runInAction(() => {
      this.blogStore.readableBlogs = [];
      this.blogStore.readableBlogs = readableBlogs;
      readableBlogs.forEach((blog) => {
        blog.postCategories.forEach((postCategory) => {
          if (
            postCategory.postCategoryTypeId in
            this.blogStore.categorizedBlogsDic
          ) {
            this.blogStore.categorizedBlogsDic[
              postCategory.postCategoryTypeId
            ].push(blog);
          } else {
            this.blogStore.categorizedBlogsDic[
              postCategory.postCategoryTypeId
            ] = [blog];
          }
        });
      });
    });
  };

  updateMostLikedBlogs = (mostLikedBlogs: Array<IReadableBlog>) => {
    runInAction(() => {
      this.blogStore.mostLikedBlogs = [];
      this.blogStore.mostLikedBlogs = mostLikedBlogs;
    });
  };

  updateMostRecentBlogs = (mostRecentBlogs: Array<IReadableBlog>) => {
    runInAction(() => {
      this.blogStore.mostRecentBlogs = [];
      this.blogStore.mostRecentBlogs = mostRecentBlogs;
    });
  };

  updateBlogCategories = (blogCategories: Array<IUIBlogCategory>) => {
    runInAction(() => {
      this.blogStore.allBlogCategories = [];
      this.blogStore.allBlogCategories = blogCategories.map((category: any) => {
        const item = {
          value: category.id,
          label: category.category,
        };

        return item;
      });
    });
  };

  updateCurrEditedPostContent = (
    postMeta: IPostMeta,
    postContent: IPostContent
  ) => {
    runInAction(() => {
      this.blogStore.currEditedBlog = {
        postTitle: postMeta.postTitle,
        postContent: postContent,
        postId: postMeta.id,
        postDescription: postMeta.postDescription,
        postMetaTags: postMeta.postMetaTags,
      };
    });
  };

  fetchPostContentFromPostIdAndUpdateStore = async (
    postMeta: IPostMeta,
    s3Url: string
  ) => {
    if (this.getIsUserAdmin || this.getIsUserWriter) {
      this.isLoading = true;
      try {
        if (this.getUserData.email && this.getUserData.token) {
          const blogContent = await getJsonContentFromS3Url(s3Url);
          this.updateCurrEditedPostContent(postMeta, blogContent);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  };

  getBlogCategoriesList(
    postCategories: Array<IPostCategory>
  ): Array<IUIBlogCategory> {
    const categoryList: Array<IUIBlogCategory> = [];

    postCategories.forEach((postCategory) => {
      const matchingCategory = this.blogStore.allBlogCategories.find(
        (category: IUIBlogCategory) =>
          category.value === postCategory.postCategoryTypeId
      );

      if (matchingCategory) {
        categoryList.push(matchingCategory);
      }
    });

    return categoryList;
  }

  fetchMostRecentPosts = async () => {
    this.isLoading = true;
    try {
      const mostRecentBlogs = await getMostRecentBlogsFromService();
      this.updateMostRecentBlogs(mostRecentBlogs);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchMostLikedPosts = async () => {
    this.isLoading = true;
    try {
      const mostLiked = await getMostLikedBlogsFromService();
      this.updateMostLikedBlogs(mostLiked);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchPostCategories = async () => {
    this.isLoading = true;
    try {
      const postCategories = await getBlogCategoriesFromService();
      this.updateBlogCategories(postCategories);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchReadablePosts = async () => {
    this.isLoading = true;
    try {
      const readableBlogs = await getReadableBlogsFromService();
      this.updateReadableBlogs(readableBlogs);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchReaderSavedBlogsAndUpdateStore = async () => {
    this.isLoading = true;
    try {
      if (this.getUserData.token) {
        const userPosts = await getReaderSavedPostsFromService(
          this.getUserData.token
        );
        this.updateReaderSavedPosts(userPosts);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  };

  fetchWriterBlogsAndUpdateStore = async () => {
    if (this.getIsUserAdmin || this.getIsUserWriter) {
      this.isLoading = true;
      try {
        if (this.getUserData.email && this.getUserData.token) {
          const userPosts = await getWriterPostsFromService(
            this.getUserData.email,
            this.getUserData.token
          );
          this.updateWriterPosts(userPosts);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    }
  };

  isPostSaved(postId: number) {
    let isPostSaved = false;
    this.blogStore.readerSavedPosts.forEach((savedPost) => {
      if (savedPost.postMeta.id === postId) {
        isPostSaved = true;
      }
    });

    return isPostSaved;
  }

  updateReaderPostAndUpdateStore = async (
    postMeta: IPostMeta,
    metaTags: Array<string>,
    action: string
  ) => {
    try {
      let resp = null;
      if (action === readerPostAction.SAVE) {
        resp = await saveReaderBlogFromService(
          postMeta.id,
          GlobalStore.getUserData.token || ""
        );
      } else {
        resp = await deleteReaderBlogFromService(
          postMeta.id,
          GlobalStore.getUserData.token || ""
        );
      }
      if (resp === HttpStatusCode.Ok) {
        dispatchSwntEvent(swntEvents.SHOW_SUCCESS_TOAST, {
          message:
            action === readerPostAction.DELETE
              ? "Removed post from saved list"
              : "Saved post",
        });
      } else {
        dispatchSwntEvent(swntEvents.SHOW_ERROR_TOAST, {
          message:
            action === readerPostAction.DELETE
              ? "Error in removing post from saved list"
              : "Error in saving post",
        });
      }
    } catch (error) {
      console.log(error);
      dispatchSwntEvent(swntEvents.SHOW_ERROR_TOAST, {
        message:
          action === readerPostAction.DELETE
            ? "Error in removing post from saved list"
            : "Error in saving post",
      });
    } finally {
      await this.fetchReaderSavedBlogsAndUpdateStore();
    }
  };

  get getWriterBlogs() {
    return this.blogStore.writerBlogs;
  }

  get getReadableBlogs() {
    return this.blogStore.readableBlogs;
  }

  get getAllBlogCategories() {
    return this.blogStore.allBlogCategories;
  }

  get getMostLikedPosts() {
    return this.blogStore.mostLikedBlogs;
  }

  get getMostRecentPosts() {
    return this.blogStore.mostRecentBlogs;
  }

  // Blogs -- end
  // -----------------------------------------------
  // Common -- start
  fetchUserFromTokenAndUpdateStore = async () => {
    const jwtToken = Cookies.get("swnt_jwtToken");
    if (jwtToken) {
      const userData = fetchUserDetailFromCookie(jwtToken);
      if (userData) {
        this.updateUserData(userData);
      }
    }
  };

  redirectTo = (route: any) => {
    window.location.href = route;
  };

  setIsLoading(val: boolean) {
    runInAction(() => {
      this.isLoading = val;
    });
  }

  setIsUnsubscribeFlow(val: boolean) {
    runInAction(() => {
      this.isUnsubscribeFlow = val;
    });
  }

  setUnsubscribeUserEmail(val: string | undefined) {
    runInAction(() => {
      this.unsubscribeUserEmail = val;
    });
  }
  // Common -- end
  // -----------------------------------------------
  // Files -- start

  async fetchUserFilesAndUpdateStore() {
    try {
      const resp = await fetchAllImagesForUser(this.getUserData.token || "");
      this.updateUserFiles(resp);
    } catch (error) {
      throw new Error("Can't fetch user files");
    }
  }

  updateUserFiles(userFiles: Array<IUserFile>) {
    runInAction(() => {
      this.filesStore.userFiles = userFiles;
    });
  }

  get getUserFiles() {
    return this.filesStore.userFiles;
  }

  // Files -- end
  // -----------------------------------------------
}

const GlobalStore = new GlobalStoreImpl();
GlobalStore.fetchData();
export default GlobalStore;
