import {
  BookOpenIcon,
  ComputerDesktopIcon,
  KeyIcon,
  MapPinIcon,
  RocketLaunchIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export const routes = {
  HOME: "/",
  ABOUT_ME: "/about-me",
  SUBJECTS: "/subjects",
  BLOGS: "/blogs",
  MY_BLOGS: "/my-blogs",
  NEW_BLOG: "/new-blog",
  EDIT_BLOG: "/edit-blog",
  BLOG_VIEW: "/view-blog",
  UNSUBSCRIBE: "/unsubscribe",
  PRIVACY_POLICY: "/privacy-policy",
  MY_IMAGES: "/my-images",
  SAVED_BLOGS: "/saved-blogs",
};

export const GoogleOAuthData = {
  CLIENT_ID:
    "173076892370-egcq25rqqf6nkqlbvq3kvpncm92m22pd.apps.googleusercontent.com",
};

export const url = "https://prod-api.studywithniharikatiwari.com";
// export const url = "http://localhost:8080";

export const urlAuth = `${url}/auth`;

export const urlPost = `${url}/post`;

export const urlUser = `${url}/user`;

export const urlFiles = `${url}/files`;

export const devBlogUrlPrefix = "http://localhost:9000";

/**
 * "whiteAlpha" | "blackAlpha" | "gray" | "red" | "orange"
 * | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple"
 * | "pink" | "linkedin" | "facebook" | "messenger" | "whatsapp"
 * | "twitter" | "telegram"
 */
/**
 * business communication
 * business statistics
 * organisational behaviour
 * business organisation
 * inventory management
 * advertising management
 * basic accounting
 * essentials of e commerce
 * cost accounting
 */
export const categoriesColorScheme = new Map<string, string>([
  ["business communication", "green"],
  ["business statistics", "red"],
  ["organisational behaviour", "yellow"],
  ["business organisation", "teal"],
  ["inventory management", "blue"],
  ["advertising management", "pink"],
  ["basic accounting", "whatsapp"],
  ["essentials of e commerce", "telegram"],
  ["cost accounting", "purple"],
]);

export const readerPostAction = {
  SAVE: "save",
  DELETE: "delete",
};

export const swntEvents = {
  LOGIN: "login",
  SHOW_SUCCESS_TOAST: "showSuccessToast",
  SHOW_ERROR_TOAST: "showErrorToast",
};

export const userRoles = {
  ADMIN: "ADMIN",
  READER: "READER",
  WRITER: "WRITER",
};

export const postStatus = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  DELETED: "DEL",
};

export const myEmail = "mailto:studywithniharikatiwari@gmail.com";

export const GoogleAnalyticsTrackingId = "G-69BT6FVKX1";

export const aboutMeCardsData = [
  {
    icon: <MapPinIcon style={{ height: "24px" }} />,
    title: "My Journey",
    description:
      "Since I started this journey, I've believed that dedication and persistent efforts are key to achieving your goals. This belief inspired me to start my Youtube channel, where I am to help my students in pursuit of success.",
  },
  {
    icon: <UsersIcon style={{ height: "24px" }} />,
    title: "Serving the Community",
    description:
      "For over 3 years, I've been actively engaged in providing valuable content to commerce graduates (BBA/BCom/MBA/MCom). Also, I am sharing the highlights of my government job journey to share my own learning and experience.",
  },
  {
    icon: <ComputerDesktopIcon style={{ height: "24px" }} />,
    title: "Why this website?",
    description:
      "I have established this website with the main purpose of providing valuable resources and content to all those students who are in search of high-quality educational materials. Whether you are aiming to understand complex subjects or prepare for your exams efficiently, you will find content here to support your learning and achieve your academic goals.",
  },
  {
    icon: <KeyIcon style={{ height: "24px" }} />,
    title: "Credentials",
    description:
      "I completed my 12th grade in commerce and graduated with a Bachelor's in Business Administration (BBA). I am proud to have achieved the distinction of University Gold Medalist in BBA from Babasaheb Bhimrao Ambedkar University (a Central University), Lucknow. Additionaly I hold a Diploma in Elementary Education (D.El.Ed.). With over 3 years of experience in online teaching via YouTube, I have had the privilege of connecting with a community of over 55,000 subscribers.",
  },
  {
    icon: <RocketLaunchIcon style={{ height: "24px" }} />,
    title: "My Mission",
    description:
      "I am on a mission to make a difference in students lives by making them understand complex subjects easily and providing them with high-quality educational content. I am dedicated to sharing knowledge that empowers students to achieve their academic and career aspirations. For me, teaching is not just a profession; it's my passion.",
  },
  {
    icon: <BookOpenIcon style={{ height: "24px" }} />,
    title: "Explore our online courses.",
    description: `I invite you all to explore our online course, which is exclusively created to make a positive impact on your educational journey. These courses are designed to help you cover your syllabus efficiently and effectively, with a deeper understanding of the topics. The USP of this course is "Master Your Syllabus in Hours: Your Quick Path to Exam Success!"  It is designed in such a way that it covers the entire syllabus of subjects that typically require 2-3 months of study in just 1-2 hours. This course serves as a rapid revision for those students who are already prepared and a lifesaver for those who haven't studied yet. It's a blessing for all those students who want to grasp the entire syllabus quickly before exams to prevent failure.`,
  },
];
