import React, { FC } from "react";
import {
  IReadableBlog,
  IUIBlogCategory,
} from "../../interfaces/GlobalInterfaces";
import { Center, SimpleGrid } from "@chakra-ui/react";
import PostCard from "../post-card/PostCard";
import GlobalStore from "../../store/GlobalStore";
import { convertTimeFormat } from "../../utils/GlobalUtils";

interface CategorizedBlogSectionProps {
  blogs: Array<IReadableBlog>;
}

const CategorizedBlogSection: FC<CategorizedBlogSectionProps> = ({
  blogs,
}): JSX.Element | null => {
  return (
    <Center>
      <SimpleGrid
        spacing={{ sm: "0", md: "20px" }}
        columns={{ base: 1, md: 2, xl: 3 }}
      >
        {blogs?.map((post) => (
          <PostCard
            showDescription
            postDescription={post.postMeta.postDescription}
            showImage={false}
            isLoading={false}
            key={post.postMeta.id}
            maxWidth={{ sm: "auto", md: "400px" }}
            postId={post.postMeta.id}
            postCategory={GlobalStore.getBlogCategoriesList(
              post.postCategories
            ).map((category: IUIBlogCategory) => category.label)}
            minWidth={{ sm: "auto", md: "400px" }}
            postImgUrl={
              post.postMeta.postImageUrl
                ? (post.postMeta.postImageUrl as string)
                : "https://via.placeholder.com/150"
            }
            postTitle={post.postMeta.postTitle}
            postWriterName={post.postMeta.userName}
            timeToRead={post.postMeta.timeToRead}
            createOn={convertTimeFormat(post.postMeta.createdOn)}
          />
        ))}
      </SimpleGrid>
    </Center>
  );
};

export default CategorizedBlogSection;
