import React, { FC, useEffect, useState } from "react";
import GlobalStore from "../../store/GlobalStore";
import { dispatchSwntEvent } from "../../utils/GlobalUtils";
import { observer } from "mobx-react";
import { IPostMeta } from "../../interfaces/GlobalInterfaces";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { BookmarkSlashIcon } from "@heroicons/react/24/solid";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { readerPostAction, swntEvents } from "../../utils/Constants";
import posthog from "posthog-js";

type SaveActionProps = {
  postMeta: IPostMeta;
  metaTags: Array<string>;
};

const SaveAction: FC<SaveActionProps> = observer(({ postMeta, metaTags }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isSaved, setIsSaved] = useState(GlobalStore.isPostSaved(postMeta.id));

  const toggleSave = async (action: string) => {
    if (!GlobalStore.getIsLoggedIn) {
      // POSTHOG: Capture login modal is shown after user clicks in save post
      posthog.capture("save_blog_login_dialog_shown");
      dispatchSwntEvent(swntEvents.LOGIN, {
        title: "Sign up to save this story",
        description:
          "Save stories to your personalized lists and access them anytime, anywhere",
      });
      return;
    } else {
      setIsSaved((preVal) => !preVal);
      // POSTHOG: Capture what type of save action is taken by user
      posthog.capture(`${action}_action_on_save_blog`);
      GlobalStore.updateReaderPostAndUpdateStore(postMeta, metaTags, action);
    }
  };

  useEffect(() => {
    const showSaveActionHelper = () => {
      setTimeout(() => onToggle(), 2000);
    };

    showSaveActionHelper();
  }, []);

  return isSaved ? (
    <Tooltip hasArrow label="Remove">
      <BookmarkSlashIcon
        onClick={() => toggleSave(readerPostAction.DELETE)}
        style={{
          color: "#805AD5",
          height: "18px",
          cursor: "pointer",
        }}
      />
    </Tooltip>
  ) : (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom-end"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <BookmarkIcon
            onClick={() => toggleSave(readerPostAction.SAVE)}
            style={{ height: "18px", cursor: "pointer" }}
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold">
            Bookmark Now & Read Anytime!
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>Saved blogs are in your profile.</PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
});

export default SaveAction;
