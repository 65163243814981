import axios, { HttpStatusCode } from "axios";
import { urlAuth } from "../utils/Constants";
import { getDomainFromURL } from "../utils/GlobalUtils";

async function validateOAuthIDToken(authCode: string) {
  const body = {
    authCode: authCode,
    redirection_url: getDomainFromURL(window.location.href),
  };
  try {
    const resp = await axios.post(`${urlAuth}/validate-id-token`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (err) {
    throw new Error("Cannot validate oAuth id token");
  }
}

async function emailAuth(email: string, password: string) {
  const body = {
    email: email,
    password: password,
  };
  try {
    const resp = await axios.post(`${urlAuth}/login`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (err) {
    throw new Error("Login failed");
  }
}

async function registerUser(
  firstName: string,
  lastName: string,
  email: string,
  password: string
) {
  const body = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
  };
  try {
    const resp = await axios.post(`${urlAuth}/register`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return resp.status;
  } catch (err: any) {
    if (err.response.status === HttpStatusCode.Conflict) {
      throw new Error("A user with this email already exists");
    }
    throw new Error("Registration failed");
  }
}

export { validateOAuthIDToken, emailAuth, registerUser };
