import {
  IEditorJsBlock,
  IPostContent,
  IPostMeta,
} from "../interfaces/GlobalInterfaces";
import { categoriesColorScheme, devBlogUrlPrefix } from "./Constants";

function getHumanReadableTime(timestampString: string): string {
  const timestamp: Date = new Date(timestampString as string);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC", // Make sure to set the correct timezone if needed
  };

  const humanReadableTime: string = timestamp.toLocaleDateString(
    "en-US",
    options
  );

  return humanReadableTime;
}

function toTitleCase(str: string | undefined) {
  if (str === undefined) {
    return "";
  }
  return str.replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
}

function calculateReadingTime(editor: IPostContent | any) {
  let wordCount = 0;

  editor.blocks.forEach((block: IEditorJsBlock) => {
    if (
      block.type === "paragraph" ||
      block.type === "heading" ||
      block.type === "list"
    ) {
      wordCount += block.data.text ? block.data.text.split(" ").length : 0;
    }
  });

  const averageWordsPerMinute = 200;
  const readingTime = Math.round(wordCount / averageWordsPerMinute);

  return readingTime;
}

function convertTimeFormat(inputTime: string) {
  const date = new Date(inputTime);

  const formattedDate = date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return formattedDate;
}

function getDomainFromURL(url: string) {
  const parsedURL = new URL(url);
  return parsedURL.protocol + "//" + parsedURL.host;
}

function getBlogUrl(postMeta: IPostMeta | undefined): string {
  if (!postMeta) {
    return "";
  }
  return process.env.NODE_ENV === "development"
    ? `${devBlogUrlPrefix}/${postMeta.id}.json`
    : postMeta.s3Url;
}

function dispatchSwntEvent(eventName: string, data?: object) {
  const event = new CustomEvent(eventName, {
    detail: data,
  });
  window.dispatchEvent(event);
}

function getHeadingSizeFromTitleType(titleType: string) {
  switch (titleType) {
    case "H1":
      return { size: "2xl", as: "h1" };
    case "H2":
      return { size: "xl", as: "h2" };
    case "H3":
      return { size: "lg", as: "h3" };
    case "H4":
      return { size: "md", as: "h4" };
    case "H5":
      return { size: "sm", as: "h5" };
    case "H6":
      return { size: "xs", as: "h6" };
    default:
      return { size: "2xl", as: "h2" };
  }
}

function getHeadingSizeFromLevel(level: number) {
  switch (level) {
    case 1:
      return { size: "2xl", as: "h1" };
    case 2:
      return { size: "xl", as: "h2" };
    case 3:
      return { size: "lg", as: "h3" };
    case 4:
      return { size: "md", as: "h4" };
    case 5:
      return { size: "sm", as: "h5" };
    case 6:
      return { size: "xs", as: "h6" };
    default:
      return { size: "2xl", as: "h2" };
  }
}

function getCategoryColorScheme(category: string): string {
  if (categoriesColorScheme.has(category)) {
    return categoriesColorScheme.get(category) || "green";
  }
  return "green";
}

export {
  getDomainFromURL,
  getHumanReadableTime,
  toTitleCase,
  calculateReadingTime,
  convertTimeFormat,
  getBlogUrl,
  dispatchSwntEvent,
  getHeadingSizeFromTitleType,
  getHeadingSizeFromLevel,
  getCategoryColorScheme,
};
