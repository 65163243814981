import axios from "axios";
import { urlUser } from "../utils/Constants";

async function getAllUsers(jwtToken: string) {
  try {
    const resp = await axios.get(`${urlUser}/all`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    console.log(resp);

    return resp.data;
  } catch (error) {
    console.log(error);
    throw new Error("Can't fetch all users");
  }
}

async function updateSubscriptionStatus(email: string, subscription: boolean) {
  try {
    const resp = await axios.get(
      `${urlUser}/subscribe?email=${email}&subscription=${subscription}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    return resp.status;
  } catch (error) {
    console.log(error);
    throw new Error("Can't subscribe");
  }
}

export { getAllUsers, updateSubscriptionStatus };
