import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../lotties/mail.json";
import LoginOrSignUp from "../login/LoginOrSignUp";
import SocialLinks from "./SocialLinks";
import GlobalStore from "../../store/GlobalStore";
import { updateSubscriptionStatus } from "../../service/UserService";
import { myEmail, routes } from "../../utils/Constants";
import { observer } from "mobx-react";
import Logo from "../header/Logo";
import { Link } from "react-router-dom";
import { HttpStatusCode } from "axios";

const Footer = observer((): JSX.Element => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubscribeClick = async () => {
    if (GlobalStore.getIsLoggedIn && GlobalStore.getUserData.email) {
      setIsLoading(true);
      try {
        const resp = await updateSubscriptionStatus(
          GlobalStore.getUserData.email,
          true
        );
        if (resp === HttpStatusCode.Ok) {
          toast({
            description: `Subscribed`,
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            description: `Cannot subscribe, Please try again later`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error);
        toast({
          description: `Cannot subscribe, Please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      onOpen();
    }
  };

  return (
    <Box marginTop="20px" padding="50px 20px">
      <Divider marginBottom="20px" />
      <Flex width="100%" alignItems="center" direction="column">
        {!GlobalStore.isUnsubscribeFlow &&
        (!GlobalStore.getUserData.email ||
          (GlobalStore.getUserData.email &&
            GlobalStore.getUserData.isSubscribedToNewsLetter === "NO")) ? (
          <>
            <Box
              width="fit-content"
              borderRadius="50%"
              backgroundColor="#FFFFFF"
            >
              <Lottie options={defaultOptions} width={150} />
            </Box>
            <Text textAlign="center" fontSize="30px" marginTop="20px">
              Subscribe For The Latest Updates
            </Text>
            <Text
              textAlign="center"
              fontSize="18px"
              fontWeight="normal"
              color="#9A9494"
            >
              Subscribe to newsletter and never miss a new post
            </Text>
            <Button
              isLoading={isLoading}
              marginTop="20px"
              width={{ base: "100%", md: "auto" }}
              padding="0 20px 0 20px"
              onClick={handleSubscribeClick}
              colorScheme="purple"
              size="lg"
            >
              Subscribe
            </Button>

            <Divider marginTop="20px" />
          </>
        ) : (
          <></>
        )}
        <LoginOrSignUp isOpen={isOpen} onClose={onClose} isSubscribeFlow />
      </Flex>
      <Container>
        <Flex
          marginTop="50px"
          direction="column"
          width="100%"
          justifyContent="space-between"
        >
          <Logo />
          <SocialLinks />
          <Flex
            direction={{ base: "column", md: "row" }}
            marginTop="26px"
            marginBottom="16px"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Link style={{ textDecoration: "underline" }} to={routes.HOME}>
              Home
            </Link>
            <Link style={{ textDecoration: "underline" }} to={routes.ABOUT_ME}>
              About Me
            </Link>
            <Link style={{ textDecoration: "underline" }} to={myEmail}>
              Contact Me
            </Link>
            <Link
              style={{ textDecoration: "underline" }}
              to={routes.PRIVACY_POLICY}
            >
              Privacy Policy
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
});

export default Footer;
