import { useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { swntEvents } from "../../utils/Constants";

const SwntToast = () => {
  const toast = useToast();

  const handleSuccessToast = (event: Event) => {
    const customEvent = event as CustomEvent;
    const { message } = customEvent.detail;
    toast({
      description: message,
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  const handleErrorToast = (event: Event) => {
    const customEvent = event as CustomEvent;
    const { message } = customEvent.detail;
    toast({
      description: message,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  };

  // TODO: Refactor code to use this instead of useToast everywhere
  // Add event listener
  useEffect(() => {
    window.addEventListener(swntEvents.SHOW_SUCCESS_TOAST, handleSuccessToast);

    return () => {
      window.removeEventListener(swntEvents.SHOW_ERROR_TOAST, handleErrorToast);
    };
  }, []);

  return <></>;
};

export default SwntToast;
