import {
  Badge,
  Box,
  Center,
  Code,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { Key, useEffect, useRef, useState } from "react";
import {
  getJsonContentFromS3Url,
  getPostFromPostIdFromService,
} from "../service/BlogService";
import EditorParser from "../components/editor-parser/EditorParser";
import { useLocation } from "react-router-dom";
import "./BlogView.css";
import PostWriterDetails from "../components/post-writer-details/PostWriterDetails";
import { IPostMeta, IUIBlogCategory } from "../interfaces/GlobalInterfaces";
import GlobalStore from "../store/GlobalStore";
import PostActions from "../components/post-actions/PostActions";
import {
  convertTimeFormat,
  getBlogUrl,
  getCategoryColorScheme,
  toTitleCase,
} from "../utils/GlobalUtils";
import GoBackButton from "../components/go-back-button/GoBackButton";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import SimilarPosts from "../components/similar-posts/SimilarPosts";
import { HashtagIcon } from "@heroicons/react/24/outline";

const BlogView = observer(() => {
  const location = useLocation();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [blocks, setBlocks] = useState();
  const [tags, setTags] = useState<Array<string>>();
  const [postMeta, setPostMeta] = useState<IPostMeta>();
  const [similarPosts, setSimilarPosts] = useState<Array<IPostMeta>>();
  const [metaTags, setMetaTags] = useState<Array<string>>();
  const [tagsColorscheme, setTagsColorScheme] = useState<string>("green");
  const shouldFetch = useRef(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchPostContent = async () => {
      try {
        // In case we directly land on blog view or refresh on blog view, we will not have
        // blog categories
        if (GlobalStore.blogStore.allBlogCategories.length === 0) {
          await GlobalStore.fetchPostCategories();
        }
        const postId = location.pathname.split("/")[2];
        if (!postId || postId === undefined) {
          toast({
            description: `Cannot fetch blog content, please try again later`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          const userEmail = GlobalStore.getUserData.email
            ? GlobalStore.getUserData.email
            : "anonymous";
          const resp = await getPostFromPostIdFromService(
            Number(postId),
            userEmail
          );

          const postContent = await getJsonContentFromS3Url(
            getBlogUrl(resp.postMeta)
          );

          setBlocks(postContent);
          const tags = GlobalStore.getBlogCategoriesList(
            resp.postCategories
          ).map((category: IUIBlogCategory) => category.label);

          setTags(tags);
          setTagsColorScheme(getCategoryColorScheme(tags[0]));
          setPostMeta(resp.postMeta);
          setSimilarPosts(resp.moreLikeThisPost);
          if (resp.postMeta?.postMetaTags) {
            const tempMetaTags = resp.postMeta.postMetaTags
              .split(",")
              .map((item: string) => item.trim());
            setMetaTags(tempMetaTags);
          }
        }
      } catch (error) {
        toast({
          description: `Cannot fetch blog content, please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (shouldFetch.current) {
      shouldFetch.current = false;
      fetchPostContent();
    }
  }, []);
  return isLoading ? (
    <>
      <Helmet>
        <title>Niharika.academy</title>
        <meta
          name="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <Center className="blog-view-spinner-container">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    </>
  ) : blocks !== undefined ? (
    <>
      <Helmet>
        <title>{postMeta?.postTitle}</title>
        <meta name="description" content={postMeta?.postDescription} />
        <meta property="og:title" content={postMeta?.postTitle} />
        <meta property="og:description" content={postMeta?.postDescription} />
        <meta property="og:image" content={postMeta?.postImageUrl} />
        <meta property="og:image:url" content={postMeta?.postImageUrl} />
        <meta property="og:image:type" content="image/png" />
        <meta name="keywords" content={postMeta?.postMetaTags} />
      </Helmet>
      <Container marginBottom="80px" className="blog-view-container">
        <Flex
          alignItems="center"
          marginTop="50px"
          marginBottom="20px"
          direction="row"
          gap={2}
        >
          <GoBackButton />
          {tags && (
            <Code key={tags[0] as Key} ml="1" colorScheme={tagsColorscheme}>
              {toTitleCase(tags[0])}
            </Code>
          )}
        </Flex>
        {postMeta && (
          <>
            <Heading marginBottom="20px" as="h1" size="2xl">
              {postMeta.postTitle}
            </Heading>
            <PostWriterDetails
              postWriterName={postMeta.userName}
              createOn={convertTimeFormat(postMeta.createdOn)}
              showMinsToRead={false}
            />
            <PostActions
              metaTags={metaTags || []}
              numberOfLikes={postMeta.numberOfLikes}
              postMeta={postMeta}
            />
            <EditorParser
              postTitle={postMeta.postTitle || ""}
              editorData={blocks}
            />
            <Container overflowX="hidden">
              {metaTags && metaTags?.length > 0 && (
                <Flex alignItems="center" marginTop="20px" direction="row">
                  <HashtagIcon height="5rem" />
                  <Box>
                    {metaTags.map((metaTag) => (
                      <Code
                        marginTop={{ base: "6px", md: "0" }}
                        colorScheme="green"
                        marginRight="4px"
                      >
                        {metaTag.toLowerCase()}
                      </Code>
                    ))}
                  </Box>
                </Flex>
              )}
            </Container>
          </>
        )}
      </Container>
      <Container maxW="8xl">
        {similarPosts && <SimilarPosts similarPosts={similarPosts} />}
      </Container>
    </>
  ) : (
    <>Error</>
  );
});

export default BlogView;
