import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleOAuthData, routes } from "./utils/Constants";
import Error from "./screens/Error";
import MyBlogs from "./screens/MyBlogs";
import Subjects from "./screens/Subjects";
import AboutMe from "./screens/AboutMe";
import LandingPage from "./screens/LandingPage";
import Home from "./screens/Home";
import WriteBlog from "./screens/WriteBlog";
import Blogs from "./screens/Blogs";
import BlogView from "./screens/BlogView";
import Unsubscribe from "./screens/Unsubscribe";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import MyImages from "./screens/MyImages";
import posthog from "posthog-js";
import ReaderSavedPosts from "./screens/ReaderSavedPosts";
import GlobalStore from "./store/GlobalStore";
import { observer } from "mobx-react";

const App = observer(() => {
  if (
    !window.location.host.includes("127.0.0.1") &&
    !window.location.host.includes("localhost")
  ) {
    posthog.init("phc_IVXqpahR4FRrfR6zRU7HpCd9SW3X0FlVO5slLGAuVls", {
      api_host: "https://us.i.posthog.com",
    });
  }
  return (
    <ChakraProvider>
      <GoogleOAuthProvider clientId={GoogleOAuthData.CLIENT_ID}>
        <BrowserRouter>
          <Routes>
            <Route
              errorElement={<Error />}
              path={routes.HOME}
              element={<LandingPage />}
            >
              <Route index element={<Home />}></Route>
              <Route path={routes.SUBJECTS} element={<Subjects />} />
              <Route path={routes.ABOUT_ME} element={<AboutMe />} />
              <Route path={routes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={routes.MY_BLOGS} element={<MyBlogs />} />
              <Route path={routes.MY_IMAGES} element={<MyImages />} />
              <Route
                path={routes.SAVED_BLOGS}
                element={
                  GlobalStore.getIsLoggedIn ? (
                    <ReaderSavedPosts />
                  ) : (
                    <Navigate to={routes.HOME} replace />
                  )
                }
              />
              <Route path={routes.BLOGS} element={<Blogs />} />
              <Route
                path={`${routes.UNSUBSCRIBE}/:userEmail`}
                element={<Unsubscribe />}
              />
              <Route
                path={routes.NEW_BLOG}
                element={
                  GlobalStore.getIsUserWriter ? (
                    <WriteBlog isEditBlogWorkflow={false} />
                  ) : (
                    <Navigate to={routes.HOME} replace />
                  )
                }
              />
              <Route
                path={routes.EDIT_BLOG}
                element={
                  GlobalStore.getIsUserWriter ? (
                    <WriteBlog isEditBlogWorkflow={true} />
                  ) : (
                    <Navigate to={routes.HOME} replace />
                  )
                }
              />
              <Route
                path={`${routes.BLOG_VIEW}/:postId`}
                element={<BlogView />}
              />
            </Route>
            <Route path="*" element={<Navigate to={routes.HOME} replace />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
});

export default App;
