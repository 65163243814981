import { Select } from "chakra-react-select";
import React, { FC, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { IReadableBlog } from "../../interfaces/GlobalInterfaces";

type BlogSearchAutoCompleteProps = {
  blogs: Array<IReadableBlog>;
  filterBlog: CallableFunction;
};

const BlogSearchAutoComplete: FC<BlogSearchAutoCompleteProps> = ({
  blogs,
  filterBlog,
}): JSX.Element => {
  const [blogSearchOptions, setBlogSearchOptions] = useState<
    Array<{ label: string; value: number }>
  >([]);

  const handleSelectChange = (selectedOptions: any) => {
    filterBlog(selectedOptions.value);
  };

  // Build blog search options
  useEffect(() => {
    const tempBlogSearchOptions = blogs.map((blog) => {
      return {
        value: blog.postMeta.id,
        label: blog.postMeta.postTitle,
      };
    });

    setBlogSearchOptions(tempBlogSearchOptions);
  }, [blogs]);

  return (
    <Flex direction="column">
      <Text fontWeight="normal" fontSize="small">
        Search Blog
      </Text>
      <Select
        onChange={handleSelectChange}
        size="sm"
        tagVariant="solid"
        options={blogSearchOptions}
        placeholder="Search blog..."
      />
    </Flex>
  );
};

export default BlogSearchAutoComplete;
