import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import parse from "html-react-parser";

type TableParserProps = {
  withHeading?: boolean;
  content?: Array<Array<string>>;
};

const TableParser: FC<TableParserProps> = ({
  withHeading,
  content,
}): JSX.Element => {
  const [headings, setHeadings] = useState<Array<string>>([]);
  const [rows, setRows] = useState<Array<Array<string>>>([]);

  useEffect(() => {
    if (content) {
      const tempHeadings = content[0];
      setHeadings(tempHeadings);

      const tempRows = content.splice(1);
      setRows(tempRows);
    }
  }, [content, withHeading]);

  return (
    <Box marginTop="16px" marginBottom="16px">
      <Table
        display={{ base: "none", md: "table" }}
        variant="striped"
        size="sm"
        colorScheme="purple"
      >
        <Thead>
          <Tr>
            {headings.map((heading) => (
              <Th key={heading}>{heading}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row) => (
            <Tr>
              {row.map((rowItem) => (
                <Td key={rowItem}>{parse(rowItem)}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex gap={3} direction="column" display={{ base: "flex", md: "none" }}>
        {rows.map((row, row_idx) => (
          <Flex
            bgColor={row_idx % 2 === 0 ? "purple.300" : "transparent"}
            gap={2}
            padding="6px"
            direction="column"
          >
            {row.map((rowItem, idx) => (
              <SimpleGrid alignItems="center" columns={2}>
                <Heading size="xs">{headings[idx].toUpperCase()}</Heading>
                <Text borderLeft="1px" paddingLeft="6px" fontWeight="normal">
                  {parse(rowItem)}
                </Text>
              </SimpleGrid>
            ))}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default TableParser;
