import React, { FC, ReactElement } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";

type AboutMeProps = {
  height: string;
  icon: ReactElement;
  title: string;
  description: string;
};

const AboutMeCard: FC<AboutMeProps> = ({
  height,
  icon,
  title,
  description,
}): JSX.Element => {
  return (
    <Card height={height} variant="outline">
      <CardHeader>
        <Flex gap={2} direction="row">
          {icon}
          <Heading
            style={{ fontWeight: "normal" }}
            color="purple.500"
            size="md"
          >
            {title}
          </Heading>
        </Flex>
      </CardHeader>
      <CardBody>
        <Text style={{ fontWeight: "normal" }}>{description}</Text>
      </CardBody>
    </Card>
  );
};

export default AboutMeCard;
