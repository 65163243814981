import { observer } from "mobx-react";
import React from "react";
import {
  Box,
  Container,
  Heading,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import BlogPreviewCard from "../components/blog-preview-card/BlogPreviewCard";
import { Key } from "react";
import { AddIcon } from "@chakra-ui/icons";
import GlobalStore from "../store/GlobalStore";
import { useNavigate } from "react-router-dom";
import { postStatus, routes } from "../utils/Constants";
import { Helmet } from "react-helmet";

const MyBlogs = observer(() => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>My Blogs | Niharika.academy</title>
        <meta
          property="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <Container maxW="8xl" marginTop="50px">
        <Heading size="md">{`My Blogs (${GlobalStore.getWriterBlogs.length}):`}</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={2}>
          {GlobalStore.getWriterBlogs
            .filter((blog) => blog.postMeta.postStatus !== postStatus.DELETED)
            .map((blog) => (
              <BlogPreviewCard key={blog.postMeta.id as Key} blog={blog} />
            ))}
        </SimpleGrid>
        <Box>
          <Box
            position="fixed"
            bottom="20px"
            right={["16px", "84px"]}
            zIndex={3}
          >
            <IconButton
              onClick={() => navigate(routes.NEW_BLOG)}
              isRound={true}
              size="lg"
              icon={<AddIcon />}
              aria-label="Create a new blog"
              colorScheme="whatsapp"
              variant="solid"
            />
          </Box>
        </Box>
      </Container>
    </>
  );
});

export default MyBlogs;
