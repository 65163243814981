import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import ReactGA from "react-ga";
import { GoogleAnalyticsTrackingId, swntEvents } from "../utils/Constants";
import GlobalStore from "../store/GlobalStore";
import { Helmet } from "react-helmet";
import posthog from "posthog-js";
import { dispatchSwntEvent } from "../utils/GlobalUtils";
import SwntToast from "../components/swnt-toast/SwntToast";

const LandingPage = observer(() => {
  ReactGA.initialize(GoogleAnalyticsTrackingId);
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);

    const isUnsubscribeFlow: boolean =
      location.pathname.split("/")[1] === "unsubscribe" ? true : false;
    GlobalStore.setIsUnsubscribeFlow(isUnsubscribeFlow);
    if (isUnsubscribeFlow) {
      GlobalStore.setUnsubscribeUserEmail(location.pathname.split("/")[2]);
    }

    // POSTHOG: Capture pageview
    posthog.capture("$pageview");

    // scroll to top on page change
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  // Show login modal after 5 seconds
  useEffect(() => {
    const showLogin = () => {
      // POSTHOG: Capture that automatic login dialog is shown
      posthog.capture("automatic_login_dialog_shown");
      setTimeout(() => dispatchSwntEvent(swntEvents.LOGIN), 5000);
    };
    if (!GlobalStore.getIsLoggedIn) {
      showLogin();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Niharika.academy</title>
        <meta
          property="description"
          content="On this blog we provide high-quality content for commerce graduates along with guidance regarding career opportunities"
        />
      </Helmet>
      <Header />
      <Outlet />
      <Footer />

      {/* Components with events */}
      <SwntToast />
    </>
  );
});

export default LandingPage;
