import { As, Divider, Heading, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import "./EditorParser.css";
import { Markup } from "interweave";
import YouTubeChannelPreview from "../link-preview/YouTubeChannelPreview";
import YouTubePlayer from "../link-preview/YouTubePlayer";
import TableParser from "./TableParser";
import {
  IEditorParserProps,
  IEditorJSBlock,
} from "../../interfaces/GlobalInterfaces";
import ImageParser from "./ImageParser";
import {
  getHeadingSizeFromLevel,
  getHeadingSizeFromTitleType,
} from "../../utils/GlobalUtils";

const EditorParser: FC<IEditorParserProps> = ({
  editorData,
  postTitle,
}): JSX.Element => {
  const renderBlock = (block: IEditorJSBlock) => {
    switch (block.type) {
      case "image":
        return <ImageParser imageData={block.data} />;
      case "paragraph":
        return block.data.text ===
          "https://www.youtube.com/@studywithniharikatiwari1576" ? (
          <YouTubeChannelPreview />
        ) : (
          <Text marginTop="2px" marginBottom="2px" fontWeight="normal">
            <Markup content={block.data.text} />
          </Text>
        );
      case "title": {
        const { size, as } = getHeadingSizeFromTitleType(
          block.data.titleType || "H2"
        );
        return (
          <Heading marginTop="4px" marginBottom="4px" as={as as As} size={size}>
            {block.data.text}
          </Heading>
        );
      }
      case "header": {
        const { size, as } = getHeadingSizeFromLevel(block.data.level || 2);
        return (
          <Heading marginTop="4px" marginBottom="4px" as={as as As} size={size}>
            {block.data.text}
          </Heading>
        );
      }
      case "delimiter":
        return (
          <Divider
            colorScheme="blackAlpha"
            marginTop="8px"
            marginBottom="8px"
          />
        );
      case "embed":
        return <YouTubePlayer videoUrl={block.data.source} />;
      case "table":
        return (
          <TableParser
            withHeading={block.data.withHeadings}
            content={block.data.content}
          />
        );
      default:
        return null;
    }
  };

  const renderBlocks = () => {
    if (!editorData || !editorData.blocks || editorData.blocks.length === 0) {
      return null;
    }

    return editorData.blocks.map((block: IEditorJSBlock, index: number) => (
      <div key={index} className={`editor-block ${block.type}`}>
        {renderBlock(block)}
      </div>
    ));
  };

  return <div className="editor-output">{renderBlocks()}</div>;
};

export default EditorParser;
