const validatePassword = (value: any) => {
  let error;
  if (!value) {
    error = "Password is required";
  }
  return error;
};

const validateSignUpPassword = (value: any) => {
  let error;
  if (!value) {
    error = "Password is required";
  } else if (
    !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
      value
    )
  ) {
    error = "Invalid Password";
  }
  return error;
};

const validateEmail = (value: any) => {
  let error;
  if (!value) {
    error = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

const validateName = (value: any) => {
  let error;
  if (!value) {
    error = "Name is required";
  }
  return error;
};

export {
  validateEmail,
  validatePassword,
  validateSignUpPassword,
  validateName,
};
