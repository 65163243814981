import { Box, Divider, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { ShareIcon } from "@heroicons/react/24/outline";
import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import {
  BookmarkSlashIcon,
  HandThumbUpIcon as HandThumbUpIconSolid,
} from "@heroicons/react/24/solid";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import {
  isPostLikedFromService,
  likePostFromService,
  sharePostFromService,
} from "../../service/BlogService";
import GlobalStore from "../../store/GlobalStore";
import { readerPostAction, swntEvents } from "../../utils/Constants";
import { observer } from "mobx-react";
import { dispatchSwntEvent } from "../../utils/GlobalUtils";
import { IPostMeta } from "../../interfaces/GlobalInterfaces";
import { HttpStatusCode } from "axios";
import SaveAction from "./SaveAction";

interface PostActionsProps {
  metaTags: Array<string>;
  postMeta: IPostMeta;
  numberOfLikes: number;
}

const PostActions: FC<PostActionsProps> = observer(
  ({ postMeta, metaTags, numberOfLikes }): JSX.Element => {
    const [isPostLikedByUser, setIsPostLikedByUser] = useState(false);
    const [likes, setLikes] = useState(numberOfLikes);
    const shouldFetch = useRef(true);

    const handleShare = async () => {
      try {
        await navigator.clipboard.writeText(window.location.href);
        dispatchSwntEvent(swntEvents.SHOW_SUCCESS_TOAST, {
          message: "Link copied",
        });
      } catch (err) {
        dispatchSwntEvent(swntEvents.SHOW_ERROR_TOAST, {
          message: "Can't share blog",
        });
      }
      try {
        await sharePostFromService(
          postMeta.id,
          GlobalStore.getUserData.email
            ? GlobalStore.getUserData.email
            : "anonymous"
        );
      } catch (error) {
        console.log(error);
      }
    };

    const handleLike = async () => {
      try {
        const resp = await likePostFromService(
          postMeta.id,
          GlobalStore.getUserData.email
            ? GlobalStore.getUserData.email
            : "anonymous"
        );
        if (resp === HttpStatusCode.Ok) {
          setIsPostLikedByUser(true);
          setLikes((prevVal) => prevVal + 1);
        }
      } catch (error) {
        console.log(error);
        dispatchSwntEvent(swntEvents.SHOW_ERROR_TOAST, {
          message: `Can't like blog`,
        });
      }
    };

    useEffect(() => {
      const isPostLiked = async () => {
        try {
          if (
            !GlobalStore.getUserData.token ||
            !GlobalStore.getUserData.email
          ) {
            setIsPostLikedByUser(false);
            return;
          }
          const resp = await isPostLikedFromService(
            postMeta.id,
            GlobalStore.getUserData.email,
            GlobalStore.getUserData.token
          );
          setIsPostLikedByUser(resp);
        } catch (error) {
          console.log(error);
        }
      };

      if (shouldFetch.current) {
        shouldFetch.current = false;
        isPostLiked();
      }
    }, []);

    return (
      <Box marginTop="18px">
        <Divider />
        <Flex
          alignItems="center"
          padding="8px 6px"
          direction="row"
          justifyContent="space-between"
        >
          <Flex
            gap={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip hasArrow label="Like">
              {isPostLikedByUser ? (
                <HandThumbUpIconSolid
                  onClick={handleLike}
                  style={{
                    height: "18px",
                    cursor: "pointer",
                    color: "#805AD5",
                  }}
                />
              ) : (
                <HandThumbUpIcon
                  onClick={handleLike}
                  style={{ height: "18px", cursor: "pointer" }}
                />
              )}
            </Tooltip>
            <Text sx={{ fontWeight: "normal", fontSize: "12px" }}>{likes}</Text>
          </Flex>
          <Flex gap={6} alignItems="center" direction="row">
            <SaveAction postMeta={postMeta} metaTags={metaTags} />
            <Tooltip hasArrow label="Share">
              <ShareIcon
                onClick={handleShare}
                style={{ height: "18px", cursor: "pointer" }}
              />
            </Tooltip>
          </Flex>
        </Flex>
        <Divider />
      </Box>
    );
  }
);

export default PostActions;
