// Install the required dependencies
// npm install @heroicons/react @heroicons/react/outline

import React, { useState } from "react";
import {
  Grid,
  GridItem,
  Button,
  Box,
  Stack,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useColorMode,
} from "@chakra-ui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Logo from "./Logo";
import ProfileAvatar from "./ProfileAvatar";
import { observer } from "mobx-react";
import { routes } from "../../utils/Constants";
import GlobalStore from "../../store/GlobalStore";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Login from "../login/Login";
import { Link } from "react-router-dom";

const NavBar = observer(() => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box padding={{ base: "10px", md: "10px 40px 14px 50px" }}>
      <Grid templateColumns={{ base: "repeat(4, 1fr)", md: "repeat(8, 1fr)" }}>
        <GridItem h="10">
          <Logo />
        </GridItem>
        <GridItem marginTop="16px" colStart={8} h="10">
          <Stack
            display={{ base: "none", md: "flex" }}
            spacing="20px"
            direction="row"
            align="center"
          >
            <IconButton
              variant="solid"
              aria-label="toggle-theme"
              fontSize="1rem"
              onClick={toggleColorMode}
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            />
            <Link to={routes.HOME}>
              <Button width="100%" variant="ghost" size="sm">
                Home
              </Button>
            </Link>
            <Link to={routes.BLOGS}>
              <Button width="100%" variant="ghost" size="sm">
                Blogs
              </Button>
            </Link>

            <Link to={routes.ABOUT_ME}>
              <Button width="100%" variant="ghost" size="sm">
                About Me
              </Button>
            </Link>
            {GlobalStore.getIsLoggedIn ? <ProfileAvatar /> : <Login />}
          </Stack>

          {/* Menu Icon for small screens */}
          <Stack display={{ base: "block", md: "none" }} direction="row">
            <IconButton
              marginRight="20px"
              aria-label="toggle-theme"
              size="md"
              onClick={toggleColorMode}
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            />
            <IconButton
              size="md"
              icon={<Bars3Icon />}
              aria-label="Menu"
              onClick={toggleDrawer}
            />
          </Stack>
        </GridItem>
      </Grid>

      {/* Drawer for small screens */}
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={toggleDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            {/* Navigation Links for small screens */}
            <Stack marginTop="36px" spacing={5} direction="column">
              {GlobalStore.getIsLoggedIn ? (
                <ProfileAvatar toggleDrawerforMobileView={toggleDrawer} />
              ) : (
                <Login />
              )}
              <Link onClick={toggleDrawer} to={routes.HOME}>
                <Button width="100%" variant="ghost" size="sm">
                  Home
                </Button>
              </Link>
              <Link onClick={toggleDrawer} to={routes.BLOGS}>
                <Button width="100%" variant="ghost" size="sm">
                  Blogs
                </Button>
              </Link>

              <Link onClick={toggleDrawer} to={routes.ABOUT_ME}>
                <Button width="100%" variant="ghost" size="sm">
                  About Me
                </Button>
              </Link>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
});

export default NavBar;
