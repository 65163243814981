import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { FC } from "react";
import { getBlogUrl, getHumanReadableTime } from "../../utils/GlobalUtils";
import { CheckIcon, DeleteIcon, EditIcon, RepeatIcon } from "@chakra-ui/icons";
import { postStatus, routes } from "../../utils/Constants";
import {
  deleteBlogFromService,
  updateBlogStatusFromService,
} from "../../service/BlogService";
import GlobalStore from "../../store/GlobalStore";
import { useNavigate } from "react-router-dom";
import EditPostTags from "./EditPostTags";
import {
  IReadableBlog,
  IUIBlogCategory,
} from "../../interfaces/GlobalInterfaces";
import SendBlogAlertMail from "./SendBlogAlertMail";
import { HttpStatusCode } from "axios";

interface BlogPreviewCardPropsInterface {
  blog: IReadableBlog;
}

const BlogPreviewCard: FC<BlogPreviewCardPropsInterface> = observer(
  ({ blog }): JSX.Element => {
    const toast = useToast();
    const navigate = useNavigate();

    const { getUserData, fetchWriterBlogsAndUpdateStore } = GlobalStore;

    const handleUpdateBlogStatus = async (blogStatus: string) => {
      if (blog.postCategories.length === 0) {
        toast({
          description: `Please add post subject and do a manual refresh, before publishing`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        return;
      }
      try {
        if (getUserData.token) {
          const resp = await updateBlogStatusFromService(
            blog.postMeta.id,
            blogStatus,
            getUserData.token
          );
          await fetchWriterBlogsAndUpdateStore();
          if (resp === HttpStatusCode.Ok) {
            toast({
              description: `Updated blog status to: ${blogStatus}`,
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        toast({
          description: `Cannot update blog status to: ${blogStatus}, Please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        console.log(error);
      }
    };

    // TODO: Can be used in admin flow
    const handleDeleteBlog = async () => {
      try {
        if (getUserData.token) {
          const resp = await deleteBlogFromService(
            blog.postMeta.id,
            getUserData.token
          );
          await fetchWriterBlogsAndUpdateStore();
          if (resp === HttpStatusCode.Ok) {
            toast({
              description: `Deleted Blog`,
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        toast({
          description: `Cannot delete blog, Please try again later`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        console.log(error);
      }
    };

    const handleEditBlogClick = async () => {
      try {
        await GlobalStore.fetchPostContentFromPostIdAndUpdateStore(
          blog.postMeta,
          getBlogUrl(blog.postMeta)
        );
        navigate({
          pathname: routes.EDIT_BLOG,
          search: `?postTitle=${blog.postMeta.postTitle}`,
        });
      } catch (error) {
        toast({
          description: "Can't edit blog, please try again later",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    return (
      <Card variant="outline" marginTop="10px">
        <CardHeader>
          <Heading size="sm">{blog.postMeta.postTitle}</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs">Details:</Heading>
              <Stack marginTop="4px">
                <Flex marginTop="4px" direction="row">
                  <Text fontSize="xs" marginRight="4px">
                    Created On:
                  </Text>
                  <Text fontSize="xs">
                    {getHumanReadableTime(blog.postMeta.createdOn)}
                  </Text>
                </Flex>
                <Flex marginTop="4px" direction="row">
                  <Text fontSize="xs" marginRight="4px">
                    Last Updated On:
                  </Text>
                  <Text fontSize="xs">
                    {getHumanReadableTime(blog.postMeta.lastUpdatedOn)}
                  </Text>
                </Flex>
                <Flex marginTop="4px" direction="row">
                  <Text fontSize="xs" marginRight="4px">
                    Blog Status:
                  </Text>
                  <Text fontSize="xs" textTransform="capitalize">
                    {blog.postMeta.postStatus}
                  </Text>
                </Flex>
                <Flex marginTop="4px" direction="row">
                  <Text fontSize="xs" marginRight="4px">
                    Blog Tags:
                  </Text>
                  <Text fontSize="xs" textTransform="capitalize">
                    {GlobalStore.getBlogCategoriesList(blog.postCategories).map(
                      (category: IUIBlogCategory) => (
                        <>{category.label}, </>
                      )
                    )}
                  </Text>
                </Flex>
              </Stack>
            </Box>
            <Box>
              <Heading size="xs">Actions:</Heading>
              <Grid marginTop="8px" templateColumns="repeat(12, 1fr)">
                <GridItem colSpan={1}>
                  <IconButton
                    onClick={handleEditBlogClick}
                    isRound={true}
                    size="sm"
                    colorScheme="purple"
                    aria-label="Edit Blog"
                    icon={<EditIcon />}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <IconButton
                    onClick={() => handleUpdateBlogStatus(postStatus.DELETED)}
                    isRound={true}
                    size="sm"
                    colorScheme="red"
                    aria-label="Delete Blog"
                    icon={<DeleteIcon />}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <EditPostTags postId={blog.postMeta.id} />
                </GridItem>

                {/* Show send email only if blog is published */}
                {blog.postMeta.postStatus === postStatus.PUBLISHED && (
                  <GridItem colSpan={1}>
                    <SendBlogAlertMail postId={blog.postMeta.id} />
                  </GridItem>
                )}
                <GridItem colStart={12}>
                  {blog.postMeta.postStatus === postStatus.DRAFT && (
                    <Button
                      onClick={() =>
                        handleUpdateBlogStatus(postStatus.PUBLISHED)
                      }
                      size="sm"
                      rightIcon={<CheckIcon />}
                      colorScheme="teal"
                      variant="outline"
                    >
                      Publish
                    </Button>
                  )}
                  {blog.postMeta.postStatus === postStatus.PUBLISHED && (
                    <Button
                      onClick={() => handleUpdateBlogStatus(postStatus.DRAFT)}
                      size="sm"
                      rightIcon={<RepeatIcon />}
                      colorScheme="yellow"
                      variant="outline"
                    >
                      Move to Draft
                    </Button>
                  )}
                </GridItem>
              </Grid>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    );
  }
);

export default BlogPreviewCard;
