import {
  Flex,
  FocusLock,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useRef, useState } from "react";
import { TagIcon } from "@heroicons/react/24/solid";
import { Select } from "chakra-react-select";
import GlobalStore from "../../store/GlobalStore";
import { CheckIcon } from "@chakra-ui/icons";
import { updateBlogTagsFromService } from "../../service/BlogService";
import { IUIBlogCategory } from "../../interfaces/GlobalInterfaces";
import { HttpStatusCode } from "axios";

interface EditPostTagsInterface {
  postId: number;
}

const EditPostTags: FC<EditPostTagsInterface> = ({ postId }): JSX.Element => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const toast = useToast();

  const handleSelectChange = (selectedOptions: any) => {
    setSelectedTags(selectedOptions);
  };

  const handleSavePostTags = async () => {
    try {
      if (GlobalStore.getUserData.token) {
        const categories = selectedTags.map(
          (tag: IUIBlogCategory) => tag.value
        );
        const resp = await updateBlogTagsFromService(
          categories,
          postId,
          GlobalStore.getUserData.token
        );
        if (resp === HttpStatusCode.Ok) {
          toast({
            description: "Blog tags updated",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            description: "Cannot save post tags, please try again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        toast({
          description: "Cannot save post tags, please reload and try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: "Cannot save post tags, please try again later",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <IconButton
          isRound={true}
          size="sm"
          colorScheme="green"
          aria-label="Delete Blog"
          icon={<TagIcon style={{ height: "18px", cursor: "pointer" }} />}
        />
      </PopoverTrigger>
      <PopoverContent padding="30px">
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          <Flex gap="6" direction="row">
            <Select
              onChange={handleSelectChange}
              size="sm"
              isMulti
              tagVariant="solid"
              options={GlobalStore.getAllBlogCategories}
              placeholder="Select blog tags..."
            />
            <IconButton
              onClick={handleSavePostTags}
              size="sm"
              colorScheme="green"
              aria-label="Save tags"
              icon={<CheckIcon />}
            />
          </Flex>
          <PopoverCloseButton />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};

export default EditPostTags;
