import { EmailIcon } from "@chakra-ui/icons";
import { IconButton, useToast } from "@chakra-ui/react";
import React, { FC, useState } from "react";
import GlobalStore from "../../store/GlobalStore";
import { sendBlogAlertEmailFromService } from "../../service/BlogService";
import { HttpStatusCode } from "axios";

type SendBlogAlertMailProps = {
  postId: number;
};

const SendBlogAlertMail: FC<SendBlogAlertMailProps> = ({ postId }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleSendBlogAlertMaiClick = async () => {
    setIsLoading(true);
    try {
      if (GlobalStore.getUserData.token) {
        const resp = await sendBlogAlertEmailFromService(
          postId,
          GlobalStore.getUserData.token
        );

        if (resp === HttpStatusCode.Ok) {
          toast({
            description: "Blog alert sent",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            description: "Cannot send alert email, please try again",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        toast({
          description: "Cannot send alert email, please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        description: "Cannot send alert email, please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      isLoading={isLoading}
      onClick={handleSendBlogAlertMaiClick}
      isRound={true}
      size="sm"
      colorScheme="orange"
      aria-label="Send Blog Alert"
      icon={<EmailIcon />}
    />
  );
};

export default SendBlogAlertMail;
