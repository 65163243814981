import React from "react";
import { observer } from "mobx-react";
import NavBar from "./NavBar";
import { Divider } from "@chakra-ui/react";

const Header = observer(() => {
  return (
    <>
      <NavBar />
      <Divider marginBottom="50px" />
    </>
  );
});

export default Header;
