import React from "react";
import { Form, Field, Formik } from "formik";
import { FC, useState } from "react";
import {
  validateEmail,
  validateName,
  validateSignUpPassword,
} from "./LoginUtils";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { InfoOutlineIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

interface SignUpFormInterface {
  signUp: Function;
  isLoginLoading: Boolean;
}

const SignUpForm: FC<SignUpFormInterface> = ({
  signUp,
  isLoginLoading,
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = () => setShowPassword(!showPassword);

  return (
    <Formik
      initialValues={{ email: "", password: "", name: "" }}
      onSubmit={(values, actions) => signUp(values, actions)}
    >
      {(props) => (
        <Form>
          <Flex direction="row" gap="10px">
            <Field name="name" validate={validateName}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>
                    <Text fontSize="14px">Name</Text>
                  </FormLabel>
                  <Input
                    isDisabled={isLoginLoading}
                    {...field}
                    placeholder="name"
                  />
                  <FormErrorMessage>
                    <Text fontSize="12px">{form.errors.name}</Text>
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Box marginTop="14px" />
            <Field name="email" validate={validateEmail}>
              {({ field, form }: { field: any; form: any }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                >
                  <FormLabel>
                    <Text fontSize="14px">Email</Text>
                  </FormLabel>
                  <Input
                    isDisabled={isLoginLoading}
                    {...field}
                    placeholder="email"
                  />
                  <FormErrorMessage>
                    <Text fontSize="12px">{form.errors.email}</Text>
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Box marginTop="14px" />
          <Field name="password" validate={validateSignUpPassword}>
            {({ field, form }: { field: any; form: any }) => (
              <FormControl
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel>
                  <Flex alignItems="center">
                    <Text fontSize="14px">Password</Text>
                    <Tooltip
                      label="Password should have minimum eight characters, at least one letter, one number and one special character"
                      fontSize="xs"
                    >
                      <InfoOutlineIcon color="gray.500" marginLeft="4px" />
                    </Tooltip>
                  </Flex>
                </FormLabel>
                <InputGroup>
                  <Input
                    isDisabled={isLoginLoading}
                    type={showPassword ? "text" : "password"}
                    {...field}
                    placeholder="password"
                  />
                  <InputRightElement width="4.5rem">
                    {showPassword ? (
                      <IconButton
                        colorScheme="purple"
                        variant="ghost"
                        aria-label="Show Password"
                        size="sm"
                        icon={<ViewIcon />}
                        onClick={handleShowPasswordClick}
                      />
                    ) : (
                      <IconButton
                        colorScheme="purple"
                        variant="ghost"
                        aria-label="Hide Password"
                        size="sm"
                        icon={<ViewOffIcon />}
                        onClick={handleShowPasswordClick}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  <Text fontSize="12px">{form.errors.password}</Text>
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Button
            isDisabled={
              props.values.email === "" ||
              props.values.password === "" ||
              props.isSubmitting ||
              !props.isValid ||
              isLoginLoading === true
            }
            width="100%"
            marginTop="20px"
            colorScheme="purple"
            isLoading={props.isSubmitting}
            type="submit"
          >
            Sign up
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
